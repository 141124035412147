import { Box, Typography } from "@mui/material";
import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ReturnLearnWordbook from "../../button/ReturnLearnWordbook";

interface passedLayoutPropsInterface {
  amountPassed: number;
  learningWordGroupId: number;
}

export default function PassedLayoutTest(props: passedLayoutPropsInterface) {
  return (
    <>
      <Box sx={{ padding: "25px 0" }}>
        <Box textAlign={"center"} sx={{ color: "success.main", mb: 3 }}>
          <TaskAltIcon fontSize="large" />
          <Typography variant="h6">{"Вы прошли тест"}</Typography>
        </Box>
        <Box textAlign={"center"}>
          {"Поздравляю с прохождением теста! Ваш баланс уже пополнен на "}
          <Box
            component={"span"}
            sx={{ fontWeight: "bold", color: "success.main" }}
          >
            {props.amountPassed + "$"}
          </Box>
          {" Вы можете вернуться к изучению словаря."}
        </Box>
        <Box sx={{ mt: "25px" }} textAlign={"center"}>
          <ReturnLearnWordbook
            learningWordGroupId={props.learningWordGroupId}
          />
        </Box>
      </Box>
    </>
  );
}
