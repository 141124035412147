import React from "react";
import { Box, Button, Container, CssBaseline, Grid } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_BALANCE } from "../../constants/titlesConstants";
import { useSelector } from "react-redux";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import { ROUTE_GROUPS } from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import BalanceSymbol from "../../components/balance/BalanceSymbol";
import UpBalanceLayout from "../../components/balance/UpBalanceLayout";
import DownBalanceLayout from "../../components/balance/DownBalanceLayout";

function BalancePage() {
  const navigate = useNavigate();
  const balance = useSelector(userDataSelectors.userData).balance;

  const toGroups = () => {
    navigate(ROUTE_GROUPS);
  };

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_BALANCE} />
      </Container>
      <Container maxWidth="sm">
        <Box
          sx={{
            mt: 10,
            mb: 5,
            fontSize: 70,
            cursor: "default",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "primary.main",
          }}
        >
          <BalanceSymbol fontSize={70} />
          <Box component={"span"} sx={{ ml: 1 }}>
            {balance}
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", mb: 2, fontSize: 30 }}>
          {"Это ваш внутренний баланс"}
        </Box>
        <Box
          sx={{ fontSize: 16, textAlign: "center", color: "rgba(0,0,0,0.6)" }}
        >
          <Box>{"За эти средства покупаются словари для изучения."}</Box>
          <Box sx={{ color: "rgba(0,0,0,0.6)" }}>
            {
              "Заработать их можно изучая случайные слова или доступные словари."
            }
          </Box>
        </Box>
        <Box sx={{ mt: 10 }}>
          <Box>
            <Box
              sx={{
                cursor: "default",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "primary.main",
                flexDirection: "row",
                fontSize: 20,
                pr: 2,
              }}
            >
              <UpBalanceLayout different={2} fontSize={20} />
              <Box
                component={"span"}
                sx={{ ml: 2, display: "flex", alignItems: "center" }}
              >
                <BalanceSymbol fontSize={40} />
                {balance}
              </Box>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              {"За каждый правильный ответ баланс будет "}
              <Box
                component={"span"}
                sx={{ fontWeight: "bold", color: "success.main" }}
              >
                {"увеличиваться"}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: "left",
              mt: 2,
            }}
          >
            <Box
              sx={{
                cursor: "default",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "primary.main",
                flexDirection: "row",
                fontSize: 20,
                pr: 2,
              }}
            >
              <DownBalanceLayout different={-10} fontSize={20} />
              <Box
                component={"span"}
                sx={{ ml: 2, display: "flex", alignItems: "center" }}
              >
                <BalanceSymbol fontSize={40} />
                {balance}
              </Box>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              {"За каждый неправильный ответ будет "}
              <Box
                component={"span"}
                sx={{ fontWeight: "bold", color: "error.main" }}
              >
                {"уменьшаться"}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", mt: 10 }}>
          <Button variant="contained" onClick={toGroups}>
            {"Прейти к словарям"}
          </Button>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default BalancePage;
