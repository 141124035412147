import groupUpdateApi from "../../api/groupUpdate/groupUpdateApi";
import groupUpdateData from "../../api/groupUpdate/groupUpdateData";
import store from "../../redux/store";
import { learningWordIdsFromWordList } from "../learningWordIds/learningWordIdsService";

export default function handle(learningWordGroupId: number) {
  const title = store.getState().actionWordGroup.title;
  const description = store.getState().actionWordGroup.description;
  const wordList = store.getState().actionWordGroup.wordList;
  const learningWordGroupPartition =
    store.getState().actionWordGroup.learningWordGroupPartition;
  const learningWordGroupEnvironment =
    store.getState().actionWordGroup.learningWordGroupEnvironment;

  if (learningWordGroupPartition === null) {
    return;
  }

  const learningWordIds = learningWordIdsFromWordList(wordList);

  const data = groupUpdateData(
    learningWordGroupId,
    title,
    description,
    learningWordIds,
    learningWordGroupPartition.learning_word_group_partition_id,
    learningWordGroupEnvironment?.learning_word_group_environment_id ?? null,
  );

  groupUpdateApi(data);
}
