import React, { useEffect } from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import { useParams } from "react-router-dom";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import GroupDataPayComponent from "../../components/groupDataPay/GroupDataPayComponent";
import { TITLE_PAGE_GROUP_PAY } from "../../constants/titlesConstants";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import NoAccessComponent from "../../components/noAccess/NoAccessComponent";
import groupDataPayData from "../../api/groupDataPay/groupDataPayData";
import groupDataPayApi from "../../api/groupDataPay/groupDataPayApi";
import { clearGroupDataPay } from "../../redux/groupDataPay/groupDataPayReduce";
import groupDataPaySelectors from "../../redux/groupDataPay/groupDataPaySelectors";
import { clearLearnStart } from "../../redux/learnStart/learnStartReduce";
import learnStartSelectors from "../../redux/learnStart/learnStartSelectors";

export default function GroupPayPage() {
  const groupId = Number(useParams().groupId);
  const groupDataPayLoading = useSelector(
    groupDataPaySelectors.groupDataPayLoading,
  );
  const groupDataPayAccess = useSelector(
    groupDataPaySelectors.groupDataPayAccess,
  );
  const learnStartAccess = useSelector(learnStartSelectors.learnStartAccess);

  useEffect(() => {
    const data = groupDataPayData(groupId);
    groupDataPayApi(data);

    return () => {
      store.dispatch(clearGroupDataPay());
      store.dispatch(clearLearnStart());
    };
  }, []);

  function LoaderLayout() {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  function NoAccessLayout() {
    return (
      <>
        <NoAccessComponent />
      </>
    );
  }

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUP_PAY} />
        {groupDataPayAccess === false || learnStartAccess === false ? (
          <NoAccessLayout />
        ) : groupDataPayLoading ? (
          <LoaderLayout />
        ) : (
          <GroupDataPayComponent />
        )}
      </Container>
      <Footer />
    </>
  );
}
