import { createSlice } from "@reduxjs/toolkit";
import newLearningWord from "../../models/newLearningWord";

export interface learnGroupCheckStoreInterface {
  newLearningWord: newLearningWord | null;
  learnGroupCheckLoading: boolean;
  learnGroupCheckAccess: boolean;
  success: boolean | null;
  complete: boolean;
  progress: number;
}

const initStore: learnGroupCheckStoreInterface = {
  newLearningWord: null,
  learnGroupCheckLoading: false,
  learnGroupCheckAccess: true,
  success: null,
  complete: false,
  progress: 0,
};

export const learnGroupCheckReduceSlice = createSlice({
  name: "learnGroupCheckReduce",
  initialState: initStore,
  reducers: {
    clearLearnGroupCheck: (state: learnGroupCheckStoreInterface) => {
      state.newLearningWord = initStore.newLearningWord;
      state.learnGroupCheckLoading = initStore.learnGroupCheckLoading;
      state.learnGroupCheckAccess = initStore.learnGroupCheckAccess;
      state.success = initStore.success;
      state.complete = initStore.complete;
      state.progress = initStore.progress;
    },
    setLearnGroupCheckAccessTrue: (state: learnGroupCheckStoreInterface) => {
      state.learnGroupCheckAccess = true;
    },
    setLearnGroupCheckAccessFalse: (state: learnGroupCheckStoreInterface) => {
      state.learnGroupCheckAccess = false;
    },
    setNewLearningWord: (
      state: learnGroupCheckStoreInterface,
      actions: { payload: newLearningWord | null; type: string },
    ) => {
      state.newLearningWord = actions.payload;
    },
    setLearnGroupCheckLoadingTrue: (state: learnGroupCheckStoreInterface) => {
      state.learnGroupCheckLoading = true;
    },
    setLearnGroupCheckLoadingFalse: (state: learnGroupCheckStoreInterface) => {
      state.learnGroupCheckLoading = false;
    },
    setSuccessNull: (state: learnGroupCheckStoreInterface) => {
      state.success = null;
    },
    setSuccess: (
      state: learnGroupCheckStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.success = actions.payload;
    },
    setComplete: (
      state: learnGroupCheckStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.complete = actions.payload;
    },
    setProgress: (
      state: learnGroupCheckStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.progress = actions.payload;
    },
  },
});

export const {
  clearLearnGroupCheck,
  setLearnGroupCheckAccessTrue,
  setLearnGroupCheckAccessFalse,
  setNewLearningWord,
  setLearnGroupCheckLoadingTrue,
  setLearnGroupCheckLoadingFalse,
  setSuccessNull,
  setSuccess,
  setComplete,
  setProgress,
} = learnGroupCheckReduceSlice.actions;

export default learnGroupCheckReduceSlice.reducer;
