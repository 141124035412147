import { createSlice } from "@reduxjs/toolkit";
import testData from "../../models/testData";

export interface learnTestStoreInterface {
  testData: testData | null;
  learnTestLoading: boolean;
  learnTestAccess: boolean;
}

export const initData: learnTestStoreInterface = {
  testData: null,
  learnTestLoading: false,
  learnTestAccess: true,
};

export const learnTestReduceSlice = createSlice({
  name: "learnTestReduce",
  initialState: initData,
  reducers: {
    clearLearnTest: (state: learnTestStoreInterface) => {
      state.testData = initData.testData;
      state.learnTestLoading = initData.learnTestLoading;
      state.learnTestAccess = initData.learnTestAccess;
    },
    setTestData: (
      state: learnTestStoreInterface,
      action: {
        type: string;
        payload: testData;
      },
    ) => {
      state.testData = action.payload;
    },
    setLearnTestLoadingTrue: (state: learnTestStoreInterface) => {
      state.learnTestLoading = true;
    },
    setLearnTestLoadingFalse: (state: learnTestStoreInterface) => {
      state.learnTestLoading = false;
    },
    setLearnTestAccessTrue: (state: learnTestStoreInterface) => {
      state.learnTestAccess = true;
    },
    setLearnTestAccessFalse: (state: learnTestStoreInterface) => {
      state.learnTestAccess = false;
    },
  },
});

export const {
  clearLearnTest,
  setTestData,
  setLearnTestLoadingTrue,
  setLearnTestLoadingFalse,
  setLearnTestAccessTrue,
  setLearnTestAccessFalse,
} = learnTestReduceSlice.actions;

export default learnTestReduceSlice.reducer;
