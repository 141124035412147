import React from "react";
import EducationStepBase from "./EducationStepBase";
import img221 from "../../../assets/img/education/2_21.jpg";

export default function EducationStep22() {
  return (
    <EducationStepBase
      title={"Словари и доступные словари"}
      text={'Все купленные словари находятся в разделе "Доступные словари".'}
      image={img221}
    />
  );
}
