import React, { useEffect, useState } from "react";
import { Box, Button, Grow, IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { ROUTE_BALANCE } from "../../constants/routesConstants";
import BalanceSymbol from "./BalanceSymbol";
import UpBalanceLayout from "./UpBalanceLayout";
import DownBalanceLayout from "./DownBalanceLayout";

interface balanceComponentPropsInterface {
  balance: number;
}

export default function BalanceComponent(
  props: balanceComponentPropsInterface,
) {
  const [balance, setBalance] = useState(props.balance);
  const [differentBalance, setDifferentBalance] = useState(0);
  const [showUpBalance, setShowUpBalance] = useState(false);
  const [showDownBalance, setShowDownBalance] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  function toggle() {
    navigate(ROUTE_BALANCE);
  }

  function clearShowDiffBalance() {
    setShowUpBalance(false);
    setShowDownBalance(false);
  }

  useEffect(() => {
    const diffBalance = Number(props.balance) - Number(balance);
    setBalance(props.balance);
    setDifferentBalance(diffBalance);

    if (diffBalance > 0) {
      setShowUpBalance(true);
    }

    if (diffBalance < 0) {
      setShowDownBalance(true);
    }

    setTimeout(() => {
      clearShowDiffBalance();
    }, 500);
  }, [props.balance]);

  return (
    <Tooltip title="Баланс">
      <>
        <Grow in={showDownBalance || showUpBalance}>
          <Box>
            {showDownBalance ? (
              <DownBalanceLayout different={differentBalance} />
            ) : (
              ""
            )}
            {showUpBalance ? (
              <UpBalanceLayout different={differentBalance} />
            ) : (
              ""
            )}
          </Box>
        </Grow>
        <Button onClick={toggle} sx={{ fontSize: 20 }}>
          <BalanceSymbol />
          {balance}
        </Button>
      </>
    </Tooltip>
  );
}
