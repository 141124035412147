import { Box } from "@mui/material";
import React from "react";
import VerifiedIcon from "@mui/icons-material/Verified";

export function SuccessPayComponent() {
  return (
    <>
      <Box sx={{ textAlign: "center", mt: 7 }}>
        <Box color={"success.main"}>
          <VerifiedIcon sx={{ fontSize: 80 }} />
        </Box>
        <Box sx={{ fontSize: 18 }}>{"Успешная оплата"}</Box>
        <Box sx={{ mt: 4 }}>
          {
            "Оплата прошла успешно. Теперь вы можете пользоваться функционалом проекта без ограничений."
          }
        </Box>
        <Box>{"Спасибо, что доверяете нам!"}</Box>
      </Box>
    </>
  );
}
