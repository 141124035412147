export const STATUS_OK = 200;
export const CODE_FORM_ERRORS = 100;
export const STATUS_UNAUTHORIZED = 401;
export const STATUS_BAD_REQUEST = 400;
export const STATUS_FORBIDDEN = 403;
export const STATUS_NOT_ENOUGH_FUNDS = 406;
export const STATUS_SERVER_ERROR = 500;
export const STATUS_SERVER_ERROR_TEXT = "Server error";
export const STATUS_SOME_ERROR_TEXT =
  "Запрос завершился ошибкой. Попробуйте еще раз.";
export const CODE_INSUFFICIENT_FUNDS = 701;
export const CODE_NOT_FOUND_GROUP = 703;
export const CODE_NOT_FOUND_LEARN_GROUP_PROCESS = 704;
export const STATUS_NOT_EXIST_NEXT_NEW_WORD = 707;
export const NOT_TIME_FOR_NEXT_NEW_WORD = 708;
export const NOT_ACCESS_EXAM = 709;
export const NOT_ACCESS_TEST = 710;
export const CODE_NOT_TEST_NOT_FINISHED = 711;
export const CODE_GROUP_LEARN = 713;

export const CODE_REQUIRE = "101";
export const CODE_REQUIRE_TEXT = "Обязательное поле";
export const CODE_REQUIRE_ADDITIONAL_AGREEMENTS = "101.1";
export const CODE_REQUIRE_ADDITIONAL_AGREEMENTS_TEXT =
  "Примите дополнительные соглашения";
export const CODE_UNIQUE = "102";
export const CODE_UNIQUE_TEXT = "Уже используется";
export const CODE_INT_ONLY = "103";
export const CODE_INT_ONLY_TEXT = "Только числовой формат";
export const CODE_STRING_ONLY = "104";
export const CODE_STRING_ONLY_TEXT = "Только буквы";
export const CODE_EMAIL_FORMAT = "105";
export const CODE_EMAIL_FORMAT_TEXT = "Email введен неверно";
export const CODE_MIN_FORMAT = "106";
export const CODE_MIN_FORMAT_TEXT = "Слишком коротко";
export const CODE_MAX_FORMAT = "107";
export const CODE_MAX_FORMAT_TEXT = "Слишком длинно";
export const CODE_STRING_REGEX = "108";
export const CODE_STRING_REGEX_TEXT = "Неверный формат";
export const CODE_STRING_REGEX_PASSWORD = "Не короче 6 символов"; //+ "", должен состоять из A-z, 0-9 и одного из символов !$#%";
export const CODE_NOT_SAME = "109";
export const CODE_NOT_SAME_TEXT = "Не совпадает";
export const CODE_BAD_CURRENT_PASSWORD = "110";
export const CODE_BAD_CURRENT_PASSWORD_TEXT = "Неверный пароль";
export const CODE_NOT_EXIST = "111";
export const CODE_NOT_EXIST_TEXT = "Не существует в системе";

export const CODE_STATUS_UNAUTHORIZED = "401";
export const CODE_STATUS_UNAUTHORIZED_TEXT = "Неверный email или пароль";
