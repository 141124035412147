import React from "react";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Grid, Link } from "@mui/material";
import BalanceSymbol from "../balance/BalanceSymbol";
import { LoadingButton } from "@mui/lab";
import groupDataPaySelectors from "../../redux/groupDataPay/groupDataPaySelectors";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_LEARN_GROUP_NAVIGATE,
  routeGroupsEnvironmentNavigate,
} from "../../constants/routesConstants";
import learnStartData from "../../api/learnStart/learnStartData";
import learnStartApi from "../../api/learnStart/learnStartApi";
import learnStartSelectors from "../../redux/learnStart/learnStartSelectors";
import { AddBox } from "@mui/icons-material";

export default function GroupDataPayComponent() {
  const navigate = useNavigate();
  const learningWordGroupPay = useSelector(
    groupDataPaySelectors.learningWordGroupPay,
  );
  const learnStartLoading = useSelector(learnStartSelectors.learnStartLoading);
  const learnStartComplete = useSelector(
    learnStartSelectors.learnStartComplete,
  );
  const learnStartEnoughsFunds = useSelector(
    learnStartSelectors.learnStartEnoughsFunds,
  );

  function actionPay() {
    if (learningWordGroupPay !== null) {
      const data = learnStartData(learningWordGroupPay.learning_word_group_id);
      learnStartApi(data);
    }
  }

  function actionToLearn() {
    navigate(
      ROUTE_LEARN_GROUP_NAVIGATE + learningWordGroupPay?.learning_word_group_id,
    );
  }

  function PayLayout() {
    return (
      <>
        <BalanceSymbol />
        <Box component="span" sx={{ fontSize: "30px" }}>
          {learningWordGroupPay?.cost}
        </Box>
      </>
    );
  }

  function PaySaleLayout() {
    return (
      <>
        <Box component="span">
          <BalanceSymbol />
          <Box component="span" sx={{ fontSize: "30px" }}>
            {learningWordGroupPay?.sale_cost}
          </Box>
        </Box>
        <Box
          component="span"
          sx={{ textDecoration: "line-through", ml: "10px" }}
        >
          <Box component="span" sx={{ fontSize: "20px" }}>
            {learningWordGroupPay?.cost}
          </Box>
        </Box>
      </>
    );
  }

  function PayButton() {
    return (
      <>
        <LoadingButton
          loading={learnStartLoading}
          variant="contained"
          onClick={actionPay}
        >
          {"Купить"}
        </LoadingButton>
      </>
    );
  }

  function LearnButton() {
    return (
      <>
        <Button variant="contained" onClick={actionToLearn}>
          {"Перейти к изучению"}
        </Button>
      </>
    );
  }

  function toEnvironment() {
    navigate(
      routeGroupsEnvironmentNavigate(
        String(
          learningWordGroupPay?.environment?.learning_word_group_environment_id,
        ),
      ),
    );
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Box sx={{ fontSize: "18px", fontWeight: "bold" }}>
          {learningWordGroupPay?.title}
        </Box>
        <Divider />
        <Box sx={{ fontSize: "14px" }}>{learningWordGroupPay?.description}</Box>
        <Grid container sx={{ fontSize: "14px", mt: "20px" }}>
          <Grid container>
            <Grid
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                pr: "5px",
                width: 120,
              }}
              item
            >
              {"Всего слов:"}
            </Grid>
            <Grid xs item>
              {learningWordGroupPay?.info.count_word}
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                pr: "5px",
                width: 120,
              }}
              item
            >
              {"Новых для вас:"}
            </Grid>
            <Grid xs item>
              {learningWordGroupPay?.new_words_in_group}
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                pr: "5px",
                width: 120,
              }}
              item
            >
              {"Раздел:"}
            </Grid>
            <Grid xs item>
              {learningWordGroupPay?.partition.partition}
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                pr: "5px",
                width: 120,
              }}
              item
            >
              {"Сборник:"}
            </Grid>
            <Grid xs item>
              {learningWordGroupPay?.environment !== null ? (
                <Link
                  sx={{ cursor: "pointer" }}
                  component="button"
                  variant="body2"
                  onClick={toEnvironment}
                >
                  {learningWordGroupPay?.environment.environment_title}
                </Link>
              ) : (
                "---"
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2 }} />

        {learnStartEnoughsFunds === false ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "25px",
              fontSize: "16px",
              color: "error.main",
              fontWeight: "bold",
            }}
          >
            {"Недотаточно средств"}
          </Box>
        ) : (
          ""
        )}

        {learningWordGroupPay?.is_learn === false &&
        learnStartComplete !== true ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "right",
              pr: "15px",
              justifyContent: "flex-end",
            }}
          >
            {learningWordGroupPay?.cost === learningWordGroupPay?.sale_cost ? (
              <PayLayout />
            ) : (
              <PaySaleLayout />
            )}
            <Box component={"span"} sx={{ ml: 2 }}>
              <PayButton />
            </Box>
          </Box>
        ) : (
          ""
        )}
        {learningWordGroupPay?.is_learn === true ||
        learnStartComplete === true ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: "25px" }}>
            <LearnButton />
          </Box>
        ) : (
          ""
        )}
      </Container>
    </>
  );
}
