import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupAvailable from "../../models/learningWordGroupAvailable";

export interface groupAvailableListStoreInterface {
  groupAvailableListLoading: boolean;
  availableLearningWordGroups: learningWordGroupAvailable[];
}

export const initState: groupAvailableListStoreInterface = {
  groupAvailableListLoading: false,
  availableLearningWordGroups: [],
};

export const groupAvailableListReduceSlice = createSlice({
  name: "groupAvailableListReduce",
  initialState: initState,
  reducers: {
    clearGroupAvailableList: (state: groupAvailableListStoreInterface) => {
      state.groupAvailableListLoading = initState.groupAvailableListLoading;
      state.availableLearningWordGroups = initState.availableLearningWordGroups;
    },
    setGroupAvailableListLoadingTrue: (
      state: groupAvailableListStoreInterface,
    ) => {
      state.groupAvailableListLoading = true;
    },
    setGroupAvailableListLoadingFalse: (
      state: groupAvailableListStoreInterface,
    ) => {
      state.groupAvailableListLoading = false;
    },
    setAvailableLearningWordGroups: (
      state: groupAvailableListStoreInterface,
      action: { payload: learningWordGroupAvailable[]; type: string },
    ) => {
      state.availableLearningWordGroups = action.payload;
    },
  },
});

export const {
  clearGroupAvailableList,
  setGroupAvailableListLoadingTrue,
  setGroupAvailableListLoadingFalse,
  setAvailableLearningWordGroups,
} = groupAvailableListReduceSlice.actions;

export default groupAvailableListReduceSlice.reducer;
