import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routeGroupPayNavigate } from "../../constants/routesConstants";

interface noAccessGroupNavigatePropsInterface {
  groupId?: number;
}

export default function NoAccessLearn(
  props: noAccessGroupNavigatePropsInterface,
) {
  const navigate = useNavigate();

  function handle() {
    const groupId = String(props.groupId);

    navigate(routeGroupPayNavigate(groupId));
  }

  return (
    <>
      <Box>
        <Typography variant="h5">{"Нет доступа к словарю"}</Typography>
      </Box>
      <Box sx={{ pt: "25px", pb: "15px" }}>
        {
          "Нет доступа к выбранному словарю. Сейчас Вы не можете начать или продожить изучение словаря."
        }
      </Box>
      <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>
        {"Возможные причины:"}
      </Box>
      <Box sx={{ fontSize: "14px", pl: "20px" }}>
        {"- Доступ к словарю был изменен"}
      </Box>
      <Box sx={{ fontSize: "14px", pl: "20px" }}>{"- Словарь был удален "}</Box>
      <Box sx={{ fontSize: "14px", pl: "20px" }}>
        {"- Вы не приобрели словарь."}
      </Box>
      {props.groupId ? (
        <Box sx={{ mt: "25px", textAlign: "center" }}>
          <Button variant="contained" onClick={handle}>
            {"Перейти к словарю"}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
