import { Box, Button, Divider } from "@mui/material";
import React from "react";

interface PreviewLayoutPropsInterface {
  preview: React.JSX.Element;
  startFunction: CallableFunction;
}

export default function PreviewLayout(props: PreviewLayoutPropsInterface) {
  function StartButton() {
    return (
      <>
        <Box sx={{ mt: "25px" }} textAlign={"center"}>
          <Button variant="contained" onClick={() => props.startFunction()}>
            {"Начать"}
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Divider />
      {props.preview}
      <Divider />
      <StartButton />
    </>
  );
}
