import { Box, Divider } from "@mui/material";
import React from "react";
import ChooseValueComponent from "../../chooseValue/ChooseValueComponent";
import selectValueWord from "../../../models/selectValueWord";
import ProposalLayout from "./ProposalLayout";
import chooseValueWord from "../../../models/chooseValueWord";
import TranscriptionsComponent from "../../transcriptions/TranscriptionsComponent";
import AudioMultipleComponent from "../../audioComponent/AudioMultipleComponent";
import learnGroupDataSelectors from "../../../redux/learnGroupData/learnGroupDataSelectors";
import { useSelector } from "react-redux";

interface selectValueLayoutPropsInterface {
  disable: boolean;
  data: chooseValueWord;
  proposalArray: string[];
  selectHandle: CallableFunction;
  incrementStep: CallableFunction;
}

export default function SelectValueLayout(
  props: selectValueLayoutPropsInterface,
) {
  const learningWordSoundTypes = useSelector(
    learnGroupDataSelectors.learningWordSoundTypes,
  );
  const learningWordTranscriptionTypes = useSelector(
    learnGroupDataSelectors.learningWordTranscriptionTypes,
  );

  function selectValueHandle(selectValueWord: selectValueWord) {
    props.selectHandle(selectValueWord);
    props.incrementStep();
  }

  return (
    <>
      {props.proposalArray.length !== 0 ? (
        <>
          <ProposalLayout
            activeProposalPart={props.data.proposalPart ?? ""}
            proposalInArray={props.proposalArray}
          />
          <Divider />
        </>
      ) : (
        ""
      )}
      {props.data ? (
        <Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: 61,
            }}
          >
            <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
              {props.data.sounds ? (
                <AudioMultipleComponent
                  learningWordSounds={props.data.sounds}
                  learningWordSoundTypes={learningWordSoundTypes}
                />
              ) : (
                ""
              )}
              {props.data.learningWord.word}
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              fontSize: 20,
              fontStyle: "italic",
              mb: 2,
              height: 32,
            }}
          >
            {props.data.transcriptions.length > 0 ? (
              <TranscriptionsComponent
                transcriptions={props.data.transcriptions}
                learningWordTranscriptionTypes={learningWordTranscriptionTypes}
              />
            ) : (
              ""
            )}
          </Box>
          <ChooseValueComponent
            chooseValueWord={props.data}
            selectValueHandle={selectValueHandle}
            disable={props.disable}
          />
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
