import { Box, Typography } from "@mui/material";
import React from "react";

export default function NoAccessComponent() {
  return (
    <>
      <Box sx={{ p: "50px 0" }}>
        <Typography>{"Нет доступа"}</Typography>
      </Box>
    </>
  );
}
