import { storeInterface } from "../store";

const learningWordGroupPay = (state: storeInterface) =>
  state.groupDataPay.learningWordGroupPay;
const groupDataPayLoading = (state: storeInterface) =>
  state.groupDataPay.groupDataPayLoading;
const groupDataPayAccess = (state: storeInterface) =>
  state.groupDataPay.groupDataPayAccess;

export default {
  learningWordGroupPay,
  groupDataPayLoading,
  groupDataPayAccess,
};
