import React, { useEffect } from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_GROUP_ENVIRONMENT } from "../../constants/titlesConstants";
import Loader from "../../components/loader/Loader";
import { useParams } from "react-router-dom";
import EnvironmentsContainer from "../environment/EnvironmentsContainer";
import groupEnvironmentData from "../../api/groupEnvironment/groupEnvironmentData";
import groupEnvironmentApi from "../../api/groupEnvironment/groupEnvironmentApi";
import { useSelector } from "react-redux";
import groupEnvironmentSelectors from "../../redux/GroupEnvironment/groupEnvironmentSelectors";

export default function GroupEnvironmentPage() {
  const environmentId = Number(useParams().environmentId);
  const groupEnvironmentLoading = useSelector(
    groupEnvironmentSelectors.groupEnvironmentLoading,
  );
  const groupEnvironmentAccess = useSelector(
    groupEnvironmentSelectors.groupEnvironmentAccess,
  );

  useEffect(() => {
    const data = groupEnvironmentData(environmentId);
    groupEnvironmentApi(data);
  }, []);

  function NotAccessLayout() {
    return (
      <Box sx={{ fontSize: "12px", alignSelf: "center" }}>{"НЕДОСТУПЕН"}</Box>
    );
  }

  function LoaderLayout() {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  function MainLayout() {
    return <EnvironmentsContainer />;
  }

  function isStart() {
    return groupEnvironmentLoading === false && groupEnvironmentAccess === true;
  }

  function isAccess() {
    return groupEnvironmentAccess === true;
  }

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUP_ENVIRONMENT} />
      </Container>
      <Container maxWidth="md">
        {isStart() ? <MainLayout /> : <LoaderLayout />}
        {!isAccess() ? <NotAccessLayout /> : ""}
      </Container>
      <Footer />
    </>
  );
}
