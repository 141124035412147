import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_ENVIRONMENTS } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearningWordGroupEnvironments,
  setLearningWordGroupEnvironmentsLoadingFalse,
  setLearningWordGroupEnvironmentsLoadingTrue,
} from "../../redux/learningWordGroupEnvironments/learningWordGroupEnvironmentsReduce";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { groupEnvironmentsResponse } from "./groupEnvironmentsResponse";

export default function groupEnvironmentsApi() {
  startRequest();

  axios
    .get(API_GROUP_ENVIRONMENTS)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(setLearningWordGroupEnvironmentsLoadingTrue());
};

const successful = (response: responseInterface<groupEnvironmentsResponse>) => {
  store.dispatch(
    setLearningWordGroupEnvironments(
      response.data.learningWordGroupEnvironments,
    ),
  );

  store.dispatch(setLearningWordGroupEnvironmentsLoadingFalse());
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
