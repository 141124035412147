import React, { useEffect } from "react";
import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_LEARN_TEST } from "../../constants/titlesConstants";
import AppLoader from "../../components/loader/AppLoader";
import { useNavigate, useParams } from "react-router-dom";
import learnTestData from "../../api/learnTest/learnTestData";
import learnTestApi from "../../api/learnTest/learnTestApi";
import { useSelector } from "react-redux";
import learnTestSelectors from "../../redux/learnTest/learnTestSelectors";
import store from "../../redux/store";
import { clearLearnTestProcess } from "../../redux/learnTestProcess/learnTestProcessReduce";
import { clearLearnTest } from "../../redux/learnTest/learnTestReduce";
import learnTestProcessSelectors from "../../redux/learnTestProcess/learnTestProcessSelectors";
import { routeLearnGroupNavigate } from "../../constants/routesConstants";
import TestLayout from "../../components/layouts/testing/TestLayout";

export default function LearnTestGroupPage() {
  const navigate = useNavigate();
  const learningWordGroupId = Number(useParams().learningWordGroupId);
  const learningWordGroupTestId = Number(useParams().learningWordGroupTestId);

  const testData = useSelector(learnTestSelectors.testData);

  const learnTestLoading = useSelector(learnTestSelectors.learnTestLoading);
  const learnTestAccess = useSelector(learnTestSelectors.learnTestAccess);
  const learnTestProcessAccess = useSelector(
    learnTestProcessSelectors.learnTestProcessAccess,
  );
  const learnTestProcessLoading = useSelector(
    learnTestProcessSelectors.learnTestProcessLoading,
  );
  const complete = useSelector(learnTestProcessSelectors.complete);
  const selectValueWordArray = useSelector(
    learnTestProcessSelectors.selectValueWordArray,
  );

  useEffect(() => {
    const data = learnTestData(learningWordGroupTestId);
    learnTestApi(data);

    return () => {
      store.dispatch(clearLearnTest());
      store.dispatch(clearLearnTestProcess());
    };
  }, []);

  function isStart() {
    return learnTestLoading === false;
  }

  function isAccess() {
    return learnTestAccess === true && learnTestProcessAccess === true;
  }

  function handleNotAccess() {
    navigate(routeLearnGroupNavigate(String(learningWordGroupId)));
  }

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_LEARN_TEST} />
      </Container>
      {isAccess() ? (
        isStart() && testData !== null ? (
          <Container maxWidth="sm">
            <TestLayout
              learningWordGroupTestId={learningWordGroupTestId}
              learningWordGroupId={learningWordGroupId}
              learnTestLoading={learnTestLoading}
              learnTestProcessLoading={learnTestProcessLoading}
              testData={testData}
              complete={complete}
              selectValueWordArray={selectValueWordArray}
            />
          </Container>
        ) : (
          <AppLoader />
        )
      ) : (
        <Container maxWidth="sm">
          <Box sx={{ p: "20px 0" }}>
            <Typography variant="h5">{"Тест не доступен"}</Typography>
          </Box>
          <Box sx={{ mt: "25px", textAlign: "center" }}>
            <Button variant="contained" onClick={handleNotAccess}>
              {"Продолжить изучение"}
            </Button>
          </Box>
        </Container>
      )}
      <Footer />
    </>
  );
}
