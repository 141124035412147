import store from "../store";
import {
  resetPasswordLoadingFalse,
  resetPasswordLoadingTrue,
  setCountLearningWord,
  setUserDataCompleteEducation,
  updateUserDataBalance,
} from "./userDataReduce";

export function setCountLearningWordDispatch(countLearningWord: number) {
  store.dispatch(setCountLearningWord(countLearningWord));
}

export function resetPasswordLoadingTrueDispatch() {
  store.dispatch(resetPasswordLoadingTrue());
}

export function resetPasswordLoadingFalseDispatch() {
  store.dispatch(resetPasswordLoadingFalse());
}

export function setUserDataCompleteEducationDispatch(value: boolean) {
  store.dispatch(setUserDataCompleteEducation(value));
}

export function updateUserDataBalanceDispatch(value: number) {
  store.dispatch(updateUserDataBalance(value));
}
