import { storeInterface } from "../store";

const groupSearchLoading = (state: storeInterface) =>
  state.groupSearch.groupSearchLoading;
const learningWordGroups = (state: storeInterface) =>
  state.groupSearch.learningWordGroups;
const paidLearningWordGroupIds = (state: storeInterface) =>
  state.groupSearch.paidLearningWordGroupIds;

export default {
  groupSearchLoading,
  learningWordGroups,
  paidLearningWordGroupIds,
};
