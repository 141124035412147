import { storeInterface } from "../store";

const learnGroupNextAccess = (state: storeInterface) =>
  state.learnGroupNext.learnGroupNextAccess;
const learnGroupNext = (state: storeInterface) =>
  state.learnGroupNext.learnGroupNext;
const learnGroupNextLoading = (state: storeInterface) =>
  state.learnGroupNext.learnGroupNextLoading;

export default {
  learnGroupNextAccess,
  learnGroupNext,
  learnGroupNextLoading,
};
