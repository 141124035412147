import React, { useEffect, useState } from "react";
import TimerComponent from "../../components/timer/TimerComponent";
import { Box, Divider } from "@mui/material";
import selectValueWord from "../../models/selectValueWord";
import PreviewLayout from "../../components/layouts/testing/PreviewLayout";
import SelectValueLayout from "../../components/layouts/testing/SelectValueLayout";
import TimeoutLayout from "../../components/layouts/testing/TimeoutLayout";
import chooseValueWord from "../../models/chooseValueWord";
import Loader from "../../components/loader/Loader";
import EnglishVariantChecker from "../../components/layouts/learn/EnglishVariantChecker";

interface propsInterface {
  endHandle: CallableFunction;
  preview: React.JSX.Element;
  loading: boolean;
  processLoading: boolean;
  complete: boolean | null;
  min: number;
  sec: number;
  learningWordGroupId: number;
  data: chooseValueWord[];
  proposalArray: string[];
  passedLayout: React.JSX.Element;
  notPassedLayout: React.JSX.Element;
  showNotPassedLayout: boolean;
}

export default function TestingContainer(props: propsInterface) {
  const [step, setStep] = useState(0);
  const [finish, setFinish] = useState(false);
  const [startTest, setStartTest] = useState(false);
  const [endByTimeout, setEndByTimeout] = useState(false);
  const requestSelectValueWord: selectValueWord[] = [];
  const countChooseValueWords = props.data.length;

  function start() {
    setStartTest(true);
  }

  function end() {
    setFinish(true);

    props.endHandle(requestSelectValueWord);
  }

  function reStart() {
    setStartTest(false);
    setEndByTimeout(false);
  }

  function endTimeHandle() {
    setEndByTimeout(true);
  }

  function selectHandle(selectValueWord: selectValueWord) {
    requestSelectValueWord.push(selectValueWord);
  }

  function isStartTest() {
    return (
      finish === false &&
      startTest === true &&
      endByTimeout === false &&
      props.loading === false
    );
  }

  function incrementStep() {
    setStep(step + 1);

    if (isEnd()) {
      end();
    }
  }

  function isEnd() {
    return step + 1 >= countChooseValueWords;
  }

  function MainLayout() {
    return (
      <>
        {finish === false && endByTimeout === false ? (
          <>
            <Box textAlign={"center"} sx={{ pb: "25px" }}>
              <TimerComponent
                minutes={props.min}
                seconds={props.sec}
                endTimeHandle={endTimeHandle}
                start={startTest}
              />
            </Box>
            {isStartTest() ? <EnglishVariantChecker /> : ""}
            <Divider />
          </>
        ) : (
          ""
        )}
        {finish === false && startTest === false ? (
          <>
            <PreviewLayout startFunction={start} preview={props.preview} />
          </>
        ) : (
          ""
        )}

        {isStartTest() ? (
          <>
            <Box textAlign={"center"}>
              {step + 1 + " из " + countChooseValueWords}
            </Box>
            <SelectValueLayout
              disable={false}
              data={props.data[step]}
              proposalArray={props.proposalArray}
              selectHandle={selectHandle}
              incrementStep={incrementStep}
            />
          </>
        ) : (
          ""
        )}
        {endByTimeout ? <TimeoutLayout action={reStart} /> : ""}
        {props.complete === true ? <>{props.passedLayout}</> : ""}
        {props.showNotPassedLayout ? <>{props.notPassedLayout}</> : ""}
      </>
    );
  }

  return (
    <>{props.loading || props.processLoading ? <Loader /> : <MainLayout />}</>
  );
}
