import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_DATA_PAY } from "../../constants/apiRoutConstants";
import {
  CODE_NOT_FOUND_GROUP,
  STATUS_FORBIDDEN,
} from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setGroupDataPayAccessFalse,
  setGroupDataPayAccessTrue,
  setGroupDataPayLoadingFalse,
  setGroupDataPayLoadingTrue,
  setLearningWordGroupPay,
} from "../../redux/groupDataPay/groupDataPayReduce";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { groupDataPayRequest } from "./groupDataPayRequest";
import { groupDataPayResponse } from "./groupDataPayResponse";

export default function groupDataPayApi(data: groupDataPayRequest) {
  startRequest();

  axios
    .get(API_GROUP_DATA_PAY, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(setGroupDataPayLoadingTrue());
};

const successful = (response: responseInterface<groupDataPayResponse>) => {
  if (
    response.statusCode === CODE_NOT_FOUND_GROUP ||
    response.statusCode === STATUS_FORBIDDEN
  ) {
    store.dispatch(setGroupDataPayAccessFalse());
    return;
  }

  store.dispatch(setGroupDataPayAccessTrue());
  store.dispatch(setLearningWordGroupPay(response.data.learningWordGroupPay));
  store.dispatch(setGroupDataPayLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
