import { Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import actionWordGroupSelectors from "../../../redux/actionWordGroup/actionWordGroupSelectors";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import {
  setDescription,
  setTitle,
} from "../../../redux/actionWordGroup/actionWordGroupReduce";
import stepperSelectors from "../../../redux/stepper/stepperSelectors";
import {
  setNextStepFalse,
  setNextStepTrue,
} from "../../../redux/stepper/stepperReduce";

function TitleField(props: {
  title: string;
  changeTitle: CallableFunction;
  disabled: boolean;
}) {
  return (
    <TextField
      value={props.title}
      onChange={(e: any) => props.changeTitle(e.target.value)}
      label="* Введите название для словаря"
      variant="outlined"
      sx={{ width: "100%" }}
      size="small"
      disabled={props.disabled}
    />
  );
}

function DescriptionField(props: {
  description: string;
  changeDescription: CallableFunction;
  disabled: boolean;
}) {
  return (
    <TextField
      value={props.description}
      onChange={(e: any) => props.changeDescription(e.target.value)}
      label="* Краткое описание (на основании чего собран словарь)"
      multiline
      rows={5}
      sx={{ width: "100%" }}
      size="small"
      disabled={props.disabled}
    />
  );
}

interface propsInterface {
  disabled: boolean;
}

export default function TitleStepComponent(
  props: propsInterface = { disabled: false },
) {
  const title = useSelector(actionWordGroupSelectors.title);
  const description = useSelector(actionWordGroupSelectors.description);
  const isNextStep = useSelector(stepperSelectors.isNextStep);

  useEffect(() => {
    const isNext = title.length !== 0 && description.length !== 0;

    if (isNextStep !== isNext) {
      isNext === false
        ? store.dispatch(setNextStepFalse())
        : store.dispatch(setNextStepTrue());
    }
  }, [title, description]);

  function changeTitle(value: string) {
    store.dispatch(setTitle(value));
  }

  function changeDescription(value: string) {
    store.dispatch(setDescription(value));
  }

  return (
    <>
      <Box sx={{ mt: "15px;" }}>
        <TitleField
          title={title}
          changeTitle={changeTitle}
          disabled={props.disabled}
        />
      </Box>
      <Box sx={{ mt: "15px;" }}>
        <DescriptionField
          description={description}
          changeDescription={changeDescription}
          disabled={props.disabled}
        />
      </Box>
    </>
  );
}
