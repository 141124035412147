import { createSlice } from "@reduxjs/toolkit";
import { reload } from "../../helpers/redirectHelper";
import {
  storageClearToken,
  storageGetEnglishContentVariant,
  storageGetLastLearnId,
  storageGetLastLearnType,
  storageGetToken,
  storageSetEnglishContentVariant,
  storageSetLastLearnId,
  storageSetLastLearnType,
  storageSetToken,
} from "../../helpers/localStorageHelper";

export interface appStoreInterface {
  appDataLoading: boolean;
  showAppLoader: boolean;
  token: string;
  tokenLoading: boolean;
  tokenComplete: boolean | null;
  temporaryToken: string;
  temporaryTokenLoading: boolean;
  autoPlaySound: boolean;
  autoPlaySoundTest: boolean;
  autoPlayTypeSound: number | null;
  englishContentVariant: "british" | "american";
  subscribeCost: number;
  supportEmail: string;
  logoutLoading: boolean;
  appVersion: number;
  completeLearningWordChooseTrue: number;
  appInUpdate: boolean;
  lastLearnType: null | "group" | "personal";
  lastLearnId: null | number;
}

export const initStateAppReduce: appStoreInterface = {
  appDataLoading: true,
  showAppLoader: true,
  token: storageGetToken,
  tokenLoading: false,
  tokenComplete: null,
  temporaryToken: "",
  temporaryTokenLoading: false,
  autoPlaySound: false,
  autoPlaySoundTest: false,
  autoPlayTypeSound: null,
  englishContentVariant: storageGetEnglishContentVariant,
  subscribeCost: 0,
  supportEmail: "",
  logoutLoading: false,
  appVersion: 1,
  completeLearningWordChooseTrue: 100000,
  appInUpdate: false,
  lastLearnType: storageGetLastLearnType(),
  lastLearnId: storageGetLastLearnId(),
};

const appReduceSlice = createSlice({
  name: "appReduce",
  initialState: initStateAppReduce,
  reducers: {
    startAppLoaded: (state: appStoreInterface) => {
      state.showAppLoader = true;
    },
    stopAppLoaded: (state: appStoreInterface) => {
      state.showAppLoader = false;
    },
    setToken: (state: appStoreInterface, action) => {
      storageSetToken(action.payload);
      reload();
    },
    clearToken: (state: appStoreInterface) => {
      storageClearToken();
      reload();
    },
    setTokenLoadingTrue: (state: appStoreInterface) => {
      state.tokenLoading = true;
    },
    setTokenLoadingFalse: (state: appStoreInterface) => {
      state.tokenLoading = false;
    },
    setTokenComplete: (
      state: appStoreInterface,
      action: {
        payload: boolean | null;
        type: string;
      },
    ) => {
      state.tokenComplete = action.payload;
    },
    setTemporaryToken: (state: appStoreInterface, action) => {
      state.temporaryToken = action.payload;
    },
    clearTemporaryToken: (state: appStoreInterface) => {
      state.temporaryToken = "";
    },
    temporaryTokenLoadingTrue: (state: appStoreInterface) => {
      state.temporaryTokenLoading = true;
    },
    temporaryTokenLoadingFalse: (state: appStoreInterface) => {
      state.temporaryTokenLoading = false;
    },
    setAppDataLoadingTrue: (state: appStoreInterface) => {
      state.appDataLoading = true;
    },
    setAppDataLoadingFalse: (state: appStoreInterface) => {
      state.appDataLoading = false;
    },
    setAutoPlaySoundTrue: (state: appStoreInterface) => {
      state.autoPlaySound = true;
    },
    setAutoPlaySoundFalse: (state: appStoreInterface) => {
      state.autoPlaySound = false;
    },
    setAutoPlaySoundTestTrue: (state: appStoreInterface) => {
      state.autoPlaySoundTest = true;
    },
    setAutoPlaySoundTestFalse: (state: appStoreInterface) => {
      state.autoPlaySoundTest = false;
    },
    setAutoPlayTypeSound: (
      state: appStoreInterface,
      action: {
        payload: number | null;
        type: string;
      },
    ) => {
      state.autoPlayTypeSound = action.payload;
    },
    setEnglishContentVariant: (
      state: appStoreInterface,
      action: {
        payload: "british" | "american";
        type: string;
      },
    ) => {
      state.englishContentVariant = action.payload;
      storageSetEnglishContentVariant(action.payload);
    },
    setSubscribeCost: (
      state: appStoreInterface,
      action: {
        payload: number;
        type: string;
      },
    ) => {
      state.subscribeCost = action.payload;
    },
    setSupportEmail: (
      state: appStoreInterface,
      action: {
        payload: string;
        type: string;
      },
    ) => {
      state.supportEmail = action.payload;
    },
    setLogoutLoading: (
      state: appStoreInterface,
      action: {
        payload: boolean;
        type: string;
      },
    ) => {
      state.logoutLoading = action.payload;
    },
    setAppVersion: (
      state: appStoreInterface,
      action: {
        payload: number;
        type: string;
      },
    ) => {
      state.appVersion = action.payload;
    },
    setCompleteLearningWordChooseTrue: (
      state: appStoreInterface,
      action: {
        payload: number;
        type: string;
      },
    ) => {
      state.completeLearningWordChooseTrue = action.payload;
    },
    setAppInUpdate: (
      state: appStoreInterface,
      action: {
        payload: boolean;
        type: string;
      },
    ) => {
      state.appInUpdate = action.payload;
    },
    setLastLearnType: (
      state: appStoreInterface,
      action: {
        payload: null | "group" | "personal";
        type: string;
      },
    ) => {
      state.lastLearnType = action.payload;
      storageSetLastLearnType(state.lastLearnType ?? "");
    },
    setLastLearnId: (
      state: appStoreInterface,
      action: {
        payload: number | null;
        type: string;
      },
    ) => {
      state.lastLearnId = action.payload;
      storageSetLastLearnId(String(state.lastLearnId) ?? "");
    },
  },
});

export const {
  setAppDataLoadingTrue,
  setAppDataLoadingFalse,
  startAppLoaded,
  stopAppLoaded,
  setToken,
  setTokenLoadingTrue,
  setTokenLoadingFalse,
  setTokenComplete,
  clearToken,
  setTemporaryToken,
  clearTemporaryToken,
  temporaryTokenLoadingTrue,
  temporaryTokenLoadingFalse,
  setAutoPlaySoundTrue,
  setAutoPlaySoundFalse,
  setAutoPlaySoundTestTrue,
  setAutoPlaySoundTestFalse,
  setEnglishContentVariant,
  setSubscribeCost,
  setSupportEmail,
  setLogoutLoading,
  setAppVersion,
  setCompleteLearningWordChooseTrue,
  setAppInUpdate,
  setLastLearnType,
  setLastLearnId,
} = appReduceSlice.actions;

export default appReduceSlice.reducer;
