import { Box } from "@mui/material";
import React from "react";

export default function ErrorTextComponent(props: { errorText: string }) {
  return (
    <>
      {props.errorText.length !== 0 ? (
        <Box
          sx={{
            color: "#9c0000",
            fontWeight: "bold",
            fontSize: "16px",
            p: "10px 0",
          }}
        >
          {props.errorText}
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
