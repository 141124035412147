import learningWordGroupAvailable from "../../models/learningWordGroupAvailable";
import store from "../store";
import {
  clearGroupAvailableList,
  setAvailableLearningWordGroups,
  setGroupAvailableListLoadingFalse,
  setGroupAvailableListLoadingTrue,
} from "./groupAvailableListReduce";

export function clearGroupAvailableListDispatch() {
  store.dispatch(clearGroupAvailableList());
}

export function setAvailableLearningWordGroupsDispatch(
  value: learningWordGroupAvailable[],
) {
  store.dispatch(setAvailableLearningWordGroups(value));
}

export function setGroupAvailableListLoadingTrueDispatch() {
  store.dispatch(setGroupAvailableListLoadingTrue());
}

export function setGroupAvailableListLoadingFalseDispatch() {
  store.dispatch(setGroupAvailableListLoadingFalse());
}
