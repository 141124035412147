import { storeInterface } from "../store";

const learnStartLoading = (state: storeInterface) =>
  state.learnStart.learnStartLoading;
const learnStartComplete = (state: storeInterface) =>
  state.learnStart.learnStartComplete;
const learnStartAccess = (state: storeInterface) =>
  state.learnStart.learnStartAccess;
const learnStartEnoughsFunds = (state: storeInterface) =>
  state.learnStart.learnStartEnoughsFunds;

export default {
  learnStartLoading,
  learnStartComplete,
  learnStartAccess,
  learnStartEnoughsFunds,
};
