import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_LEARN_EXAM } from "../../constants/titlesConstants";
import AppLoader from "../../components/loader/AppLoader";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { clearLearnTestProcess } from "../../redux/learnTestProcess/learnTestProcessReduce";
import { clearLearnExam } from "../../redux/learnExam/learnExamReduce";
import learnExamSelectors from "../../redux/learnExam/learnExamSelectors";
import learnExamData from "../../api/learnExam/learnExamData";
import learnExamApi from "../../api/learnExam/learnExamApi";
import learnExamProcessSelectors from "../../redux/learnExamProcess/learnExamProcessSelectors";
import { routeLearnGroupNavigate } from "../../constants/routesConstants";
import ExamProcessLayout from "../../components/layouts/testing/ExamProcessLayout";
import { clearLearnExamProcessDispatch } from "../../redux/learnExamProcess/learnExamProcessDispatches";

export default function LearnExamGroupPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const learningWordGroupId = Number(useParams().learningWordGroupId);
  const testData = useSelector(learnExamSelectors.testData);
  const learnExamLoading = useSelector(learnExamSelectors.learnExamLoading);
  const learnExamAccess = useSelector(learnExamSelectors.learnExamAccess);
  const learnExamProcessLoading = useSelector(
    learnExamProcessSelectors.learnExamProcessLoading,
  );
  const learnExamProcessAccess = useSelector(
    learnExamProcessSelectors.learnExamProcessAccess,
  );
  const complete = useSelector(learnExamProcessSelectors.complete);
  const selectValueWordArray = useSelector(
    learnExamProcessSelectors.selectValueWordArray,
  );

  useEffect(() => {
    const data = learnExamData(learningWordGroupId);
    learnExamApi(data);

    return () => {
      store.dispatch(clearLearnExam());
      clearLearnExamProcessDispatch();
    };
  }, []);

  function isStart() {
    return learnExamLoading === false;
  }

  function isAccess() {
    return learnExamAccess === true && learnExamProcessAccess === true;
  }

  function handleNotAccess() {
    navigate(routeLearnGroupNavigate(String(learningWordGroupId)));
  }

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_LEARN_EXAM} />
      </Container>
      {isAccess() ? (
        isStart() && testData ? (
          <Container maxWidth="sm">
            <ExamProcessLayout
              learningWordGroupId={learningWordGroupId}
              learnExamLoading={learnExamLoading}
              learnExamProcessLoading={learnExamProcessLoading}
              testData={testData}
              complete={complete}
              selectValueWordArray={selectValueWordArray}
            />
          </Container>
        ) : (
          <AppLoader />
        )
      ) : (
        <Container maxWidth="sm">
          <Box sx={{ p: "20px 0" }}>
            <Typography variant="h5">{"Экзамен не доступен"}</Typography>
          </Box>
          <Box sx={{ mt: "25px", textAlign: "center" }}>
            <Button variant="contained" onClick={handleNotAccess}>
              {"Продолжить изучение"}
            </Button>
          </Box>
        </Container>
      )}
      <Footer />
    </>
  );
}
