import { alertAppError } from "../../constants/alertConstants";
import { API_LEARN_TEST_LIST } from "../../constants/apiRoutConstants";
import {
  CODE_NOT_FOUND_GROUP,
  STATUS_FORBIDDEN,
} from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { setLearningWordGroupExam } from "../../redux/groupExam/groupExamReduce";
import {
  setLearnExam,
  setLearnTestListLoadingFalse,
  setLearningWordGroupTestList,
} from "../../redux/learnTestList/learnTestListReduce";
import {
  setLearnTestListAccessFalse,
  setLearnTestListAccessTrue,
  setLearnTestListLoadingTrue,
} from "../../redux/learnTestList/learnTestListReduce";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { learnTestListRequest } from "./learnTestListRequest";
import { learnTestListResponse } from "./learnTestListResponse";

export default function learnTestListApi(data: learnTestListRequest) {
  startRequest();

  axios
    .get(API_LEARN_TEST_LIST, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(setLearnTestListLoadingTrue());
};

const successful = (response: responseInterface<learnTestListResponse>) => {
  if (
    response.statusCode === STATUS_FORBIDDEN ||
    response.statusCode === CODE_NOT_FOUND_GROUP
  ) {
    store.dispatch(setLearnTestListAccessFalse());
    return;
  }

  store.dispatch(setLearnTestListAccessTrue());
  store.dispatch(
    setLearningWordGroupTestList(response.data.learningWordGroupTestList),
  );
  store.dispatch(setLearnExam(response.data.learningWordGroupExam));

  store.dispatch(setLearnTestListLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
