import { Box, Button, Divider, Drawer, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import learnTestListData from "../../api/learnTestList/learnTestListData";
import learnTestListApi from "../../api/learnTestList/learnTestListApi";
import { useSelector } from "react-redux";
import learnTestListSelectors from "../../redux/learnTestList/learnTestListSelectors";
import Loader from "../../components/loader/Loader";
import NotFoundPage from "../pages/NotFoundPage";
import learningWordGroupTestListItem from "../../models/learningWordGroupTestListItem";
import TestListItemComponent from "../../components/testList/TestListItemComponent";
import store from "../../redux/store";
import { clearLearnTestList } from "../../redux/learnTestList/learnTestListReduce";
import { useNavigate } from "react-router-dom";
import { routeLearnExamGroupNavigate } from "../../constants/routesConstants";

export interface testLestPropsInterface {
  learningWordGroupId: number;
  show: boolean;
  toggle: CallableFunction;
}

export default function TestListContainer(props: testLestPropsInterface) {
  const navigate = useNavigate();
  const learningWordGroupTestList = useSelector(
    learnTestListSelectors.learningWordGroupTestList,
  );
  const learnExam = useSelector(learnTestListSelectors.learnExam);
  const learnTestListLoading = useSelector(
    learnTestListSelectors.learnTestListLoading,
  );
  const learnTestListAccess = useSelector(
    learnTestListSelectors.learnTestListAccess,
  );
  const container =
    window !== undefined ? () => window.document.body : undefined;

  useEffect(() => {
    return () => {
      store.dispatch(clearLearnTestList());
    };
  }, []);

  useEffect(() => {
    if (props.show === true) {
      const data = learnTestListData(props.learningWordGroupId);
      learnTestListApi(data);
    }
  }, [props.show]);

  function examHandle() {
    navigate(routeLearnExamGroupNavigate(String(props.learningWordGroupId)));
  }

  function LoadingLayout() {
    return <Loader />;
  }

  function EmptyTestLayout() {
    return (
      <Box
        sx={{ p: "15px 0 15px 10px", color: "#989898", testAlign: "center" }}
      >
        {"Нет тестов"}
      </Box>
    );
  }

  function EmptyExamLayout() {
    return (
      <Box sx={{ color: "#989898", testAlign: "center" }}>{"Нет экзамена"}</Box>
    );
  }

  function NeedChooseExamLayout() {
    return (
      <Box
        sx={{ fontSize: 12, alignSelf: "center", p: 1, color: "error.main" }}
      >
        {"Еще " +
          learnExam?.needChoose +
          " раз нужно выбрать правильное значение, чтобы попробовать сдать экзамен"}
      </Box>
    );
  }

  function ExamComplete() {
    return (
      <>
        <Box
          sx={{
            color: "#ffffff",
            backgroundColor: "#34a102",
            fontSize: "18px",
            p: "10px 0",
          }}
        >
          {"Экзамен сдан"}
        </Box>
      </>
    );
  }

  function BodyLayout() {
    return (
      <>
        <Box>
          <Box>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {"Тестирование"}
            </Typography>
            <Divider />
          </Box>
          {learningWordGroupTestList.length !== 0 ? (
            learningWordGroupTestList.map(
              (
                learningWordGroupTestListItem: learningWordGroupTestListItem,
                index: number,
              ) => {
                return (
                  <TestListItemComponent
                    key={index}
                    learningWordGroupTestListItem={
                      learningWordGroupTestListItem
                    }
                    learningWordGroupId={props.learningWordGroupId}
                  />
                );
              },
            )
          ) : (
            <EmptyTestLayout />
          )}
        </Box>
        <Divider />
        <Box sx={{ textAlign: "center", mt: "15px" }}>
          {learnExam !== null ? (
            learnExam.complete === true ? (
              <ExamComplete />
            ) : (
              <>
                {learnExam?.needChoose && learnExam?.needChoose !== 0 ? (
                  <NeedChooseExamLayout />
                ) : (
                  ""
                )}
                <Button
                  variant="contained"
                  sx={{ width: "80%" }}
                  disabled={learnExam.access === false}
                  onClick={examHandle}
                >
                  {"Экзамен"}
                </Button>
              </>
            )
          ) : (
            <EmptyExamLayout />
          )}
        </Box>
      </>
    );
  }

  function MainLayout() {
    return (
      <>
        <Box component="nav">
          <Drawer
            anchor="left"
            container={container}
            variant="temporary"
            open={props.show}
            onClose={() => {
              props.toggle();
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "300px",
              },
            }}
          >
            <Box>
              <Box
                onClick={() => {
                  props.toggle();
                }}
                sx={{
                  textAlign: "right",
                  cursor: "pointer",
                  margin: " 5px 10px",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
            {learnTestListLoading ? <LoadingLayout /> : <BodyLayout />}
            <Box
              sx={{
                textAlign: "left",
                mt: "15px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              {"* Тесты открываются по мере изучения словаря"}
            </Box>
            <Box
              sx={{
                textAlign: "left",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              {
                "* Экзамен доступен после изучения всех слов и прохождения всех тестов"
              }
            </Box>
          </Drawer>
        </Box>
      </>
    );
  }

  return <>{learnTestListAccess ? <MainLayout /> : <NotFoundPage />}</>;
}
