import { storeInterface } from "../store";

const learningWordGroupTestList = (state: storeInterface) =>
  state.learnTestList.learningWordGroupTestList;
const learnExam = (state: storeInterface) => state.learnTestList.learnExam;
const learnTestListLoading = (state: storeInterface) =>
  state.learnTestList.learnTestListLoading;
const learnTestListAccess = (state: storeInterface) =>
  state.learnTestList.learnTestListAccess;

export default {
  learningWordGroupTestList,
  learnExam,
  learnTestListLoading,
  learnTestListAccess,
};
