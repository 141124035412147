import { storeInterface } from "../store";

const newLearningWord = (state: storeInterface) =>
  state.learnGroupCheck.newLearningWord;
const learnGroupCheckLoading = (state: storeInterface) =>
  state.learnGroupCheck.learnGroupCheckLoading;
const learnGroupCheckAccess = (state: storeInterface) =>
  state.learnGroupCheck.learnGroupCheckAccess;
const success = (state: storeInterface) => state.learnGroupCheck.success;
const complete = (state: storeInterface) => state.learnGroupCheck.complete;
const progress = (state: storeInterface) => state.learnGroupCheck.progress;

export default {
  newLearningWord,
  learnGroupCheckLoading,
  learnGroupCheckAccess,
  success,
  complete,
  progress,
};
