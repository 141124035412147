import React from "react";
import wordListItem from "../../models/wordListItem";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface wordListPropsInterface {
  items: wordListItem[];
  setItems: CallableFunction;
  disabled: boolean;
}

export default function WordsListComponent(props: wordListPropsInterface) {
  const removeWord = (index: number) => {
    const newItems = Array.from(props.items);

    newItems[index] = {
      ...newItems[index],
      remove: !newItems[index].remove,
    };

    props.setItems(newItems);
  };

  const tableCellStyle = (index: number) => {
    return props.items[index].remove
      ? {
          textDecoration: "line-through",
          color: "#9c9c9c",
          backgroundColor: "#f2f2f2",
        }
      : {};
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const newItems = Array.from(props.items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    props.setItems(newItems);
  };

  function OnlyTable() {
    return (
      <>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={"120px"}></TableCell>
              <TableCell width={"30px"}>{"№"}</TableCell>
              <TableCell align="left">{"Слово"}</TableCell>
              <TableCell align="left">{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((item: wordListItem, index: number) => (
              <TableRow key={index} sx={tableCellStyle(index)}>
                <TableCell align="center">
                  <Button disabled={true}>
                    {item.remove ? "Вернуть" : "Убрать"}
                  </Button>
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell align="left">{item.learningWord.word}</TableCell>
                <TableCell align="center">{item.learningWord.public}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }

  function DragDropContextTable() {
    return (
      <>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" width={"120px"}></TableCell>
                <TableCell width={"30px"}>{"№"}</TableCell>
                <TableCell align="left">{"Слово"}</TableCell>
                <TableCell align="center">{""}</TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="table">
              {(provided: any) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {props.items.map((item: wordListItem, index: number) => (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided: any) => (
                        <TableRow
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          sx={tableCellStyle(index)}
                        >
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                removeWord(index);
                              }}
                            >
                              {item.remove ? "Вернуть" : "Убрать"}
                            </Button>
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell align="left">
                            {item.learningWord.word}
                          </TableCell>
                          <TableCell align="center">
                            {item.learningWord.public === 1 ? (
                              <CheckIcon sx={{ color: "success.main" }} />
                            ) : (
                              <WarningAmberIcon sx={{ color: "error.main" }} />
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </>
    );
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "500px" }}>
          {props.disabled === true ? <OnlyTable /> : <DragDropContextTable />}
        </TableContainer>
      </Paper>
    </>
  );
}
