import { storeInterface } from "../store";

const groupAvailableListLoading = (state: storeInterface) =>
  state.groupAvailableList.groupAvailableListLoading;
const availableLearningWordGroups = (state: storeInterface) =>
  state.groupAvailableList.availableLearningWordGroups;

export default {
  groupAvailableListLoading,
  availableLearningWordGroups,
};
