import groupUpdateRequest from "./groupUpdateRequest";

export default function groupUpdateData(
  learningWordGroupId: number,
  title: string,
  preview: string,
  learningWordIds: number[],
  learningWordGroupPartitionId: number,
  learningWordGroupEnvironmentId: number | null,
): groupUpdateRequest {
  return {
    learningWordGroupId: learningWordGroupId,
    title: title,
    preview: preview,
    learningWordIds: learningWordIds,
    learningWordGroupPartitionId: learningWordGroupPartitionId,
    learningWordGroupEnvironmentId: learningWordGroupEnvironmentId,
  };
}
