import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_ENVIRONMENT } from "../../constants/apiRoutConstants";
import { CODE_FORM_ERRORS, STATUS_FORBIDDEN } from "../../constants/errorCodes";
import {
  setGroupEnvironmentAccessFalse,
  setGroupEnvironmentLoadingFalse,
  setGroupEnvironmentLoadingTrue,
  setLearningWordGroups,
} from "../../redux/GroupEnvironment/groupEnvironmentReduce";
import { setLearningWordGroupEnvironment } from "../../redux/actionWordGroup/actionWordGroupReduce";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { groupEnvironmentRequest } from "./groupEnvironmentRequest";
import { groupEnvironmentResponse } from "./groupEnvironmentResponse";

export default function groupEnvironmentApi(data: groupEnvironmentRequest) {
  startRequest();

  axios
    .get(API_GROUP_ENVIRONMENT, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(setGroupEnvironmentLoadingTrue());
};

const successful = (response: responseInterface<groupEnvironmentResponse>) => {
  if (
    response.statusCode === STATUS_FORBIDDEN ||
    response.statusCode === CODE_FORM_ERRORS
  ) {
    store.dispatch(setGroupEnvironmentAccessFalse());
    return;
  }

  store.dispatch(
    setLearningWordGroupEnvironment(response.data.learningWordGroupEnvironment),
  );
  store.dispatch(setLearningWordGroups(response.data.learningWordGroups));

  store.dispatch(setGroupEnvironmentLoadingFalse());
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
