import { Box } from "@mui/material";
import React from "react";

interface propsInterface {
  different: number;
  fontSize?: number;
}

export default function UpBalanceLayout(props: propsInterface) {
  return (
    <>
      <Box
        sx={{
          fontSize: props.fontSize ?? 20,
          color: "success.main",
          verticalAlign: "middle",
        }}
      >
        {"+" + props.different}
      </Box>
    </>
  );
}
