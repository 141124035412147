import React from "react";
import EducationStepBase from "./EducationStepBase";
import img222 from "../../../assets/img/education/2_22.jpg";

export default function EducationStep23() {
  return (
    <EducationStepBase
      title={"Словари и доступные словари"}
      text={
        "По достижению определенного прогресса становятся доступны тесты и экзамены."
      }
      image={img222}
    />
  );
}
