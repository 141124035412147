import store from "../store";
import {
  clearCheckPay,
  setCancel,
  setCheckPayLoadingFalse,
  setCheckPayLoadingTrue,
  setError,
  setSuccess,
} from "./checkPayReduce";

export function clearCheckPayDispatch() {
  store.dispatch(clearCheckPay());
}

export function setCheckPayLoadingTrueDispatch() {
  store.dispatch(setCheckPayLoadingTrue());
}

export function setCheckPayLoadingFalseDispatch() {
  store.dispatch(setCheckPayLoadingFalse());
}

export function setSuccessDispatch(value: boolean) {
  store.dispatch(setSuccess(value));
}

export function setCancelDispatch(value: boolean) {
  store.dispatch(setCancel(value));
}

export function setErrorDispatch(value: boolean) {
  store.dispatch(setError(value));
}
