import learningWordGroupWithPartitionAndEnvironment from "../../models/learningWordGroupWithPartitionAndEnvironment";
import store from "../store";
import {
  setLearningWordGroups,
  setPaidLearningWordGroupIds,
} from "./groupSearchReduce";

export function setPaidLearningWordGroupIdsDispatch(value: number[]) {
  store.dispatch(setPaidLearningWordGroupIds(value));
}

export function setLearningWordGroupsDispatch(
  value: learningWordGroupWithPartitionAndEnvironment[],
) {
  store.dispatch(setLearningWordGroups(value));
}
