import { createSlice } from "@reduxjs/toolkit";

export interface checkPayStoreInterface {
  checkPayLoading: boolean;
  success: boolean | null;
  cancel: boolean | null;
  error: boolean | null;
}

export const initState: checkPayStoreInterface = {
  checkPayLoading: true,
  success: null,
  cancel: null,
  error: null,
};

export const checkPayReduceSlice = createSlice({
  name: "checkPayReduce",
  initialState: initState,
  reducers: {
    clearCheckPay: (state: checkPayStoreInterface) => {
      state.checkPayLoading = initState.checkPayLoading;
      state.success = initState.success;
      state.cancel = initState.cancel;
      state.error = initState.error;
    },
    setCheckPayLoadingTrue: (state: checkPayStoreInterface) => {
      state.checkPayLoading = true;
    },
    setCheckPayLoadingFalse: (state: checkPayStoreInterface) => {
      state.checkPayLoading = false;
    },
    setSuccess: (
      state: checkPayStoreInterface,
      action: { payload: boolean; type: string },
    ) => {
      state.success = action.payload;
    },
    setCancel: (
      state: checkPayStoreInterface,
      action: { payload: boolean; type: string },
    ) => {
      state.cancel = action.payload;
    },
    setError: (
      state: checkPayStoreInterface,
      action: { payload: boolean; type: string },
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  clearCheckPay,
  setCheckPayLoadingTrue,
  setCheckPayLoadingFalse,
  setSuccess,
  setCancel,
  setError,
} = checkPayReduceSlice.actions;

export default checkPayReduceSlice.reducer;
