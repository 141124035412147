import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  List,
  Typography,
} from "@mui/material";
import { countPage, elementsForPage } from "../../helpers/paginateHelper";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useNavigate } from "react-router-dom";
import { routeLearnGroupNavigate } from "../../constants/routesConstants";
import learningWordGroupAvailable from "../../models/learningWordGroupAvailable";
import learningWordGroupAvailableTest from "../../models/learningWordGroupAvailableTest";

interface propsInterface {
  availableLearningWordGroups: learningWordGroupAvailable[];
}

export default function AvailabilityGroupsContainer(props: propsInterface) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const pageSize = 30;

  function PaginationLayout() {
    const allResponseCount = props.availableLearningWordGroups.length;
    const allPagesCount = countPage(allResponseCount, pageSize);

    function handlePagination(newPage: number) {
      setPage(newPage);
    }

    return (
      <>
        {allPagesCount <= 1 ? (
          ""
        ) : (
          <PaginationComponent
            count={allPagesCount}
            page={page}
            handleChange={handlePagination}
          />
        )}
      </>
    );
  }

  function toLearnGroup(id: number) {
    navigate(routeLearnGroupNavigate(String(id)));
  }

  function EmptyLayout() {
    return (
      <Box sx={{ width: "100%", textAlign: "center", margin: "10px 0" }}>
        {"Нет доступных словарей"}
      </Box>
    );
  }

  function ProgressLayout(props: {
    countLearningWord: number;
    countWord: number;
    examComplete: boolean;
  }) {
    const progressPercent = progress(props.countLearningWord, props.countWord);

    function progress(a: number, b: number) {
      return Math.round((a / b) * 100);
    }

    function progressColor() {
      if (progressPercent < 100) {
        return "primary";
      }

      if (props.examComplete === true) {
        return "success";
      }

      return "secondary";
    }

    return (
      <LinearProgress
        variant="determinate"
        color={progressColor()}
        value={progressPercent}
      />
    );
  }

  function TestProgressLayout(props: {
    learningWordGroupAvailable: learningWordGroupAvailable;
  }) {
    function countCompleteTest() {
      return props.learningWordGroupAvailable.tests.filter(
        (item: learningWordGroupAvailableTest) => {
          return item.complete === true;
        },
      ).length;
    }

    return (
      <Box color="text.secondary" sx={{ fontSize: 12, pl: 1 }}>
        {props.learningWordGroupAvailable.tests.length > 0 ? (
          <>
            {"Тесты: "}
            {countCompleteTest()}
            {" из " + props.learningWordGroupAvailable.tests.length}
          </>
        ) : (
          ""
        )}
        {props.learningWordGroupAvailable.exam.isset === true ? (
          <>
            {" Экзамен: "}
            {props.learningWordGroupAvailable.exam.complete === true ? (
              <Box component={"span"} sx={{ color: "success.main" }}>
                {"СДАН"}
              </Box>
            ) : (
              <Box component={"span"}>{"НЕ СДАН"}</Box>
            )}
          </>
        ) : (
          ""
        )}
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", m: "15px 0" }}>
        <PaginationLayout />
      </Box>
      <List component="nav">
        {elementsForPage({
          array: props.availableLearningWordGroups,
          pageSize: pageSize,
          page: page,
        }).map(
          (
            learningWordGroupAvailable: learningWordGroupAvailable,
            index: number,
          ) => (
            <Box
              key={index}
              sx={{
                mt: 3,
              }}
            >
              <Grid container sx={{ gridTemplateColumns: "auto 130px" }}>
                <Grid item xs>
                  <Box>
                    <Box
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        backgroundColor: "rgba(0,0,0,0.05)",
                        p: 1,
                        pb: 0,
                      }}
                    >
                      {learningWordGroupAvailable.learningWordGroup.title}
                    </Box>
                    <Divider />
                    <Box sx={{ fontSize: 12, pt: 0.5, pb: 1, pl: 1 }}>
                      {learningWordGroupAvailable.learningWordGroup.preview}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={"auto"}>
                  <Button
                    onClick={() => {
                      toLearnGroup(
                        learningWordGroupAvailable.learningWordGroup
                          .learning_word_group_id,
                      );
                    }}
                    variant="contained"
                    sx={{ pt: 4, pb: 4 }}
                  >
                    {"К изучению"}
                  </Button>
                </Grid>
              </Grid>
              <Box>
                <Box color="text.secondary" sx={{ fontSize: 12, pl: 1 }}>
                  {"Прогресс изучения слов: " +
                    learningWordGroupAvailable.countLearningWord +
                    " "}
                  {" из " +
                    learningWordGroupAvailable.learningWordGroup.info
                      .count_word}
                </Box>
                <ProgressLayout
                  countLearningWord={
                    learningWordGroupAvailable.countLearningWord
                  }
                  countWord={
                    learningWordGroupAvailable.learningWordGroup.info.count_word
                  }
                  examComplete={learningWordGroupAvailable.exam.complete}
                />
                <TestProgressLayout
                  learningWordGroupAvailable={learningWordGroupAvailable}
                />
              </Box>
            </Box>
          ),
        )}
        {props.availableLearningWordGroups.length === 0 ? <EmptyLayout /> : ""}
      </List>
      <Box sx={{ display: "flex", justifyContent: "center", m: "15px 0" }}>
        <PaginationLayout />
      </Box>
    </>
  );
}
