import chooseValueWord from "../../models/chooseValueWord";
import store from "../store";
import {
  clearLearnGroupNext,
  setLearnGroupNext,
  setLearnGroupNextAccessFalse,
  setLearnGroupNextAccessTrue,
  setLearnGroupNextLoadingFalse,
  setLearnGroupNextLoadingTrue,
} from "./learnGroupNextReduce";

export function clearLearnGroupNextDispatch() {
  store.dispatch(clearLearnGroupNext());
}

export function setLearnGroupNextDispatch(learnGroupNext: chooseValueWord) {
  store.dispatch(setLearnGroupNext(learnGroupNext));
}

export function setLearnGroupNextAccessTrueDispatch() {
  store.dispatch(setLearnGroupNextAccessTrue());
}

export function setLearnGroupNextAccessFalseDispatch() {
  store.dispatch(setLearnGroupNextAccessFalse());
}

export function setLearnGroupNextLoadingTrueDispatch() {
  store.dispatch(setLearnGroupNextLoadingTrue());
}

export function setLearnGroupNextLoadingFalseDispatch() {
  store.dispatch(setLearnGroupNextLoadingFalse());
}
