import { Box, Divider, List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import groupEnvironmentSelectors from "../../redux/GroupEnvironment/groupEnvironmentSelectors";
import learningWordGroup from "../../models/learningWordGroup";
import { useNavigate } from "react-router-dom";
import { routeGroupPayNavigate } from "../../constants/routesConstants";

export default function EnvironmentsContainer() {
  const navigate = useNavigate();
  const learningWordGroupEnvironment = useSelector(
    groupEnvironmentSelectors.learningWordGroupEnvironment,
  );
  const learningWordGroups = useSelector(
    groupEnvironmentSelectors.learningWordGroups,
  );
  return (
    <>
      <Box sx={{ fontSize: "24px", fontWeight: "bold" }}>
        {learningWordGroupEnvironment?.environment_title}
      </Box>
      <Divider />
      <Box sx={{ fontSize: "12px" }}>{"Словари сборника:"}</Box>
      <List component="nav">
        {learningWordGroups.map(
          (learningWordGroup: learningWordGroup, index: number) => {
            return (
              <ListItemButton
                key={index}
                onClick={() => {
                  navigate(
                    routeGroupPayNavigate(
                      String(learningWordGroup.learning_word_group_id),
                    ),
                  );
                }}
                sx={{ fontSize: "12px", m: "3px 0" }}
              >
                {learningWordGroup.title}
              </ListItemButton>
            );
          },
        )}
      </List>
    </>
  );
}
