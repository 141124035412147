import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

interface timerComponentPropsInterface {
  minutes: number;
  seconds: number;
  endTimeHandle: CallableFunction;
  start?: boolean;
}

const TimerComponent = (props: timerComponentPropsInterface) => {
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    setMinutes(props.minutes);
    setSeconds(props.seconds);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.start !== true) {
        return;
      }

      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else if (minutes > 0) {
        setMinutes((prevMinutes) => prevMinutes - 1);
        setSeconds(59);
      } else {
        clearInterval(interval);
        props.endTimeHandle();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds, props.start]);

  return (
    <>
      <Typography variant="h3">
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </Typography>
    </>
  );
};

export default TimerComponent;
