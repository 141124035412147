import { Box, MenuItem } from "@mui/material";
import React from "react";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { useNavigate } from "react-router-dom";
import { ROUTE_SUPPORT } from "../../constants/routesConstants";

export function UnSuccessPayComponent() {
  const navigate = useNavigate();

  const toSupport = () => {
    navigate(ROUTE_SUPPORT);
  };

  return (
    <>
      <Box sx={{ textAlign: "center", mt: 7 }}>
        <Box color={"warning.main"}>
          <NewReleasesIcon sx={{ fontSize: 80 }} />
        </Box>
        <Box sx={{ fontSize: 18 }}>{"Оплата не завершена"}</Box>
        <Box sx={{ mt: 4 }}>
          {
            "По какой-то причине оплата не была завершена. Если оплата прошла, а вы видите это сообщение, то обязательно свяжитесь со "
          }
          <Box
            onClick={toSupport}
            sx={{
              cursor: "pointer",
            }}
            component={"a"}
          >
            {"службой поддрежки"}
          </Box>
          {"."}
        </Box>
        <Box>
          {"Мы приложим все усилия, чтобы как можно скорее решить ваш вопрос."}
        </Box>
        <Box>{"Спасибо, что доверяете нам!"}</Box>
      </Box>
    </>
  );
}
