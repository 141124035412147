import React, { useState } from "react";
import { Box, Button, Stack, TextField } from "@mui/material";
import PartitionsFilter from "./filters/PartitionsFilter";
import groupSearchData from "../../api/groupSearch/groupSearchData";
import groupSearchApi from "../../api/groupSearch/groupSearchApi";

const SIGNATURE_SEARCH_INPUT = "searchInput";

export default function GroupsSearchLine() {
  const [partitionId, setPartitionId] = useState(0);

  function searchSubmit(event: any) {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    const searchInput = String(form.get(SIGNATURE_SEARCH_INPUT));

    const data = groupSearchData(
      searchInput.length === 0 ? "" : searchInput,
      partitionId === 0 ? null : partitionId,
    );
    groupSearchApi(data);
  }

  function changeHandlePartitionFilter(learningWordGroupPartitionId: number) {
    setPartitionId(learningWordGroupPartitionId);
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          component="form"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            verticalAlign: "bottom",
          }}
          noValidate
          autoComplete="off"
          onSubmit={searchSubmit}
        >
          <TextField
            name={SIGNATURE_SEARCH_INPUT}
            variant="standard"
            sx={{ width: "100%", p: "5px 10px" }}
          />
          <Button variant="contained" type="submit">
            {"Найти"}
          </Button>
        </Box>
        <Stack direction="row" spacing={2}>
          <Box sx={{ maxWidth: "300px", pl: "10px" }}>
            <PartitionsFilter
              changeHandle={changeHandlePartitionFilter}
              value={partitionId}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
}
