import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_PARTITIONS } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearningWordGroupPartitions,
  setLearningWordGroupPartitionsLoadingFalse,
  setLearningWordGroupPartitionsLoadingTrue,
} from "../../redux/learningWordGroupPartitions/learningWordGroupPartitionsReduce";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { learningWordGroupPartitionsResponse } from "./learningWordGroupPartitionsResponse";

export default function learningWordGroupPartitionsApi() {
  startRequest();

  axios
    .get(API_GROUP_PARTITIONS)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(setLearningWordGroupPartitionsLoadingTrue());
};

const successful = (
  response: responseInterface<learningWordGroupPartitionsResponse>,
) => {
  store.dispatch(
    setLearningWordGroupPartitions(response.data.learningWordGroupPartitions),
  );

  store.dispatch(setLearningWordGroupPartitionsLoadingFalse());
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
