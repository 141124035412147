import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Loader from "../../loader/Loader";
import { useSelector } from "react-redux";
import learningWordGroupEnvironmentsSelectors from "../../../redux/learningWordGroupEnvironments/learningWordGroupEnvironmentsSelectors";
import actionWordGroupSelectors from "../../../redux/actionWordGroup/actionWordGroupSelectors";
import groupEnvironmentsApi from "../../../api/groupEnvironments/groupEnvironmentsApi";
import learningWordGroupEnvironment from "../../../models/learningWordGroupEnvironment";
import store from "../../../redux/store";
import { setLearningWordGroupEnvironment } from "../../../redux/actionWordGroup/actionWordGroupReduce";
import NewEnvironmentLayout from "../../layouts/newEnvironment/NewEnvironmentLayout";
import { setNextStepTrue } from "../../../redux/stepper/stepperReduce";
import { clearLearningWordGroupEnvironments } from "../../../redux/learningWordGroupEnvironments/learningWordGroupEnvironmentsReduce";

interface propsInterface {
  disabled: boolean;
}

export default function EnvironmentStepComponent(
  props: propsInterface = { disabled: false },
) {
  const learningWordGroupEnvironments = useSelector(
    learningWordGroupEnvironmentsSelectors.learningWordGroupEnvironments,
  );
  const learningWordGroupEnvironmentsLoading = useSelector(
    learningWordGroupEnvironmentsSelectors.learningWordGroupEnvironmentsLoading,
  );
  const learningWordGroupEnvironment = useSelector(
    actionWordGroupSelectors.learningWordGroupEnvironment,
  );

  useEffect(() => {
    groupEnvironmentsApi();
    store.dispatch(setNextStepTrue());
    return () => {
      store.dispatch(clearLearningWordGroupEnvironments());
    };
  }, []);

  function handleChange(event: SelectChangeEvent<number>) {
    const selectLearningWordGroupEnvironment =
      learningWordGroupEnvironments.filter(
        (item: learningWordGroupEnvironment) => {
          return item.learning_word_group_environment_id === event.target.value;
        },
      );
    store.dispatch(
      setLearningWordGroupEnvironment(
        selectLearningWordGroupEnvironment[0] ?? null,
      ),
    );
  }

  function LoaderLayout() {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  function PrimaryLayout() {
    return (
      <>
        <Box sx={{ mt: "10px;" }}>
          <Typography variant="caption">
            {
              "Добавить словарь в существующий сборник словарей, или создайте новый"
            }
          </Typography>
          <Select
            defaultValue={0}
            value={
              learningWordGroupEnvironment?.learning_word_group_environment_id ??
              0
            }
            onChange={handleChange}
            sx={{ width: "100%", fontSize: "14px;" }}
            size="small"
            disabled={props.disabled}
          >
            <MenuItem value={0}>{"Не обязательно"}</MenuItem>
            {learningWordGroupEnvironments.map(
              (item: learningWordGroupEnvironment, index: number) => (
                <MenuItem
                  key={index}
                  value={item.learning_word_group_environment_id}
                >
                  {item.environment_title}
                </MenuItem>
              ),
            )}
          </Select>
        </Box>
        <Divider textAlign="left" sx={{ m: "40px 0 15px 0" }}>
          {"Новый сборник"}
        </Divider>
        <Box sx={{ mt: "5px;" }}>
          <NewEnvironmentLayout disabled={props.disabled} />
        </Box>
      </>
    );
  }

  return (
    <>
      {learningWordGroupEnvironmentsLoading ? (
        <LoaderLayout />
      ) : (
        <PrimaryLayout />
      )}
    </>
  );
}
