import selectValueWord from "../../models/selectValueWord";
import { learnTestProcessRequest } from "./learnTestProcessRequest";

export default function learnTestProcessData(
  learningWordGroupTestId: number,
  data: selectValueWord[],
): learnTestProcessRequest {
  return {
    learningWordGroupTestId: learningWordGroupTestId,
    data: data,
  };
}
