import { storeInterface } from "../store";

const complete = (state: storeInterface) => state.learnTestProcess.complete;
const selectValueWordArray = (state: storeInterface) =>
  state.learnTestProcess.selectValueWordArray;
const learnTestProcessLoading = (state: storeInterface) =>
  state.learnTestProcess.learnTestProcessLoading;
const learnTestProcessAccess = (state: storeInterface) =>
  state.learnTestProcess.learnTestProcessAccess;

export default {
  complete,
  selectValueWordArray,
  learnTestProcessLoading,
  learnTestProcessAccess,
};
