import { createSlice } from "@reduxjs/toolkit";
import testData from "../../models/testData";

export interface learnExamStoreInterface {
  testData: testData | null;
  learnExamLoading: boolean;
  learnExamAccess: boolean;
}

export const initData: learnExamStoreInterface = {
  testData: null,
  learnExamLoading: false,
  learnExamAccess: true,
};

export const learnExamReduceSlice = createSlice({
  name: "learnExamReduce",
  initialState: initData,
  reducers: {
    clearLearnExam: (state: learnExamStoreInterface) => {
      state.testData = initData.testData;
      state.learnExamLoading = initData.learnExamLoading;
      state.learnExamAccess = initData.learnExamAccess;
    },
    setTestData: (
      state: learnExamStoreInterface,
      action: {
        type: string;
        payload: testData;
      },
    ) => {
      state.testData = action.payload;
    },
    setLearnExamLoadingTrue: (state: learnExamStoreInterface) => {
      state.learnExamLoading = true;
    },
    setLearnExamLoadingFalse: (state: learnExamStoreInterface) => {
      state.learnExamLoading = false;
    },
    setLearnExamAccessTrue: (state: learnExamStoreInterface) => {
      state.learnExamAccess = true;
    },
    setLearnExamAccessFalse: (state: learnExamStoreInterface) => {
      state.learnExamAccess = false;
    },
  },
});

export const {
  clearLearnExam,
  setTestData,
  setLearnExamLoadingTrue,
  setLearnExamLoadingFalse,
  setLearnExamAccessTrue,
  setLearnExamAccessFalse,
} = learnExamReduceSlice.actions;

export default learnExamReduceSlice.reducer;
