import React from "react";
import { Box, Grid } from "@mui/material";
import TestingContainer from "../../../containers/testing/TestingContainer";
import BalanceSymbol from "../../balance/BalanceSymbol";
import PassedLayoutTest from "./PassedLayoutTest";
import NotPassedLayout from "./NotPassedLayout";
import selectValueWord from "../../../models/selectValueWord";
import testData from "../../../models/testData";
import learnExamProcessApi from "../../../api/learnExamProcess/learnExamProcessApi";
import learnExamProcessData from "../../../api/learnExamProcess/learnExamProcessData";
import PassedLayoutExam from "./PassedLayoutExam";

interface propsInterface {
  learningWordGroupId: number;
  learnExamLoading: boolean;
  learnExamProcessLoading: boolean;
  testData: testData;
  complete: boolean | null;
  selectValueWordArray: selectValueWord[];
}

export default function ExamProcessLayout(props: propsInterface) {
  function endHandle(requestSelectValueWord: selectValueWord[]) {
    const data = learnExamProcessData(
      props.learningWordGroupId,
      requestSelectValueWord,
    );
    learnExamProcessApi(data);
  }

  function Preview() {
    return (
      <>
        <Box sx={{ p: "10px 0" }}>
          <Box
            sx={{ p: "10px 0 25px", textAlign: "center", fontWeight: "bold" }}
          >
            {"Экзамен проходится на время."}
          </Box>
          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid
              item
              xs={3}
              sx={{
                fontSize: "24px",
                color: "success.main",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {"+ " + props.testData.amountPassed}
              <BalanceSymbol />
            </Grid>
            <Grid item xs={6} sx={{ fontSize: "12px", textAlign: "center" }}>
              {"Пройдете тест, "}
              <Box sx={{ color: "success.main" }} component={"span"}>
                {"получите награду"}
              </Box>
              {". Провалитесь - "}
              <Box sx={{ color: "error.main" }} component={"span"}>
                {"получите штраф"}
              </Box>
              {"."}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                fontSize: "24px",
                color: "error.main",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {"- " + props.testData.amountNotPassed}
              <BalanceSymbol />
            </Grid>
          </Grid>
          <Box sx={{ p: "25px 0 0", textAlign: "center", fontWeight: "bold" }}>
            {"Для начала экзамена нажмите на кнопку ниже."}
          </Box>
        </Box>
        <Box sx={{ p: "10px 0" }}></Box>
      </>
    );
  }

  return (
    <>
      <TestingContainer
        endHandle={endHandle}
        preview={<Preview />}
        loading={props.learnExamLoading}
        processLoading={props.learnExamProcessLoading}
        complete={props.complete}
        min={props.testData.min}
        sec={props.testData.sec}
        learningWordGroupId={props.learningWordGroupId}
        data={props.testData.data}
        proposalArray={[]}
        passedLayout={
          <PassedLayoutExam
            learningWordGroupId={props.learningWordGroupId}
            amountPassed={props.testData.amountPassed}
          />
        }
        notPassedLayout={
          <NotPassedLayout
            selectValueWords={props.selectValueWordArray}
            learningWordGroupId={props.learningWordGroupId}
          />
        }
        showNotPassedLayout={props.selectValueWordArray.length !== 0}
      />
    </>
  );
}
