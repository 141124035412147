import { storeInterface } from "../store";

const complete = (state: storeInterface) => state.learnExamProcess.complete;
const selectValueWordArray = (state: storeInterface) =>
  state.learnExamProcess.selectValueWordArray;
const learnExamProcessLoading = (state: storeInterface) =>
  state.learnExamProcess.learnExamProcessLoading;
const learnExamProcessAccess = (state: storeInterface) =>
  state.learnExamProcess.learnExamProcessAccess;

export default {
  complete,
  selectValueWordArray,
  learnExamProcessLoading,
  learnExamProcessAccess,
};
