import learningWordSoundType from "../../models/learningWordSoundType";
import learningWordTranscriptionType from "../../models/learningWordTranscriptionType";
import newLearningWord from "../../models/newLearningWord";
import store from "../store";
import {
  clearLearnGroupData,
  setLearnGroupDataAccessFalse,
  setLearnGroupDataAccessTrue,
  setLearnGroupDataLoadingFalse,
  setLearnGroupDataLoadingTrue,
  setComplete,
  setLastNewLearningWord,
  setProgress,
  setUpdateNewWord,
  setPaidAccess,
  setLearningWordTranscriptionTypes,
  setLearningWordSoundTypes,
  setTitle,
  setCountLearningWord,
  setCountWords,
  setIssetNotPublicWord,
  setCountAccessTests,
} from "./learnGroupDataReduce";

export function clearLearnGroupDataDispatch() {
  store.dispatch(clearLearnGroupData());
}

export function setLastNewLearningWordDispatch(
  lastNewLearningWord: newLearningWord,
) {
  store.dispatch(setLastNewLearningWord(lastNewLearningWord));
}

export function setLearnGroupDataLoadingTrueDispatch() {
  store.dispatch(setLearnGroupDataLoadingTrue());
}

export function setLearnGroupDataLoadingFalseDispatch() {
  store.dispatch(setLearnGroupDataLoadingFalse());
}

export function setLearnGroupDataAccessTrueDispatch() {
  store.dispatch(setLearnGroupDataAccessTrue());
}

export function setLearnGroupDataAccessFalseDispatch() {
  store.dispatch(setLearnGroupDataAccessFalse());
}

export function setIssetNotPublicWordDispatch(value: boolean) {
  store.dispatch(setIssetNotPublicWord(value));
}

export function setTitleDispatch(value: string) {
  store.dispatch(setTitle(value));
}

export function setUpdateNewWordDispatch(updateNewWord: boolean) {
  store.dispatch(setUpdateNewWord(updateNewWord));
}

export function setCompleteDispatch(complete: boolean) {
  store.dispatch(setComplete(complete));
}

export function setProgressDispatch(progress: number) {
  store.dispatch(setProgress(progress));
}

export function setPaidAccessDispatch(paidAccess: boolean) {
  store.dispatch(setPaidAccess(paidAccess));
}

export function setCountLearningWordDispatch(value: number) {
  store.dispatch(setCountLearningWord(value));
}

export function setCountWordsDispatch(value: number) {
  store.dispatch(setCountWords(value));
}

export function setLearningWordSoundTypesDispatch(
  learningWordSoundTypes: learningWordSoundType[],
) {
  store.dispatch(setLearningWordSoundTypes(learningWordSoundTypes));
}

export function setLearningWordTranscriptionTypesDispatch(
  learningWordTranscriptionTypes: learningWordTranscriptionType[],
) {
  store.dispatch(
    setLearningWordTranscriptionTypes(learningWordTranscriptionTypes),
  );
}

export function setCountAccessTestsDispatch(value: number) {
  store.dispatch(setCountAccessTests(value));
}
