import { Box, Button, Divider, Grid, Link } from "@mui/material";
import React from "react";
import BalanceSymbol from "../../balance/BalanceSymbol";
import learningWordGroupWithPartitionAndEnvironment from "../../../models/learningWordGroupWithPartitionAndEnvironment";
import { useNavigate } from "react-router-dom";
import {
  routeGroupPayNavigate,
  routeGroupsEnvironmentNavigate,
  routeLearnGroupNavigate,
} from "../../../constants/routesConstants";

interface propsInterface {
  learningWordGroup: learningWordGroupWithPartitionAndEnvironment;
  paidLearningWordGroupIds: number[];
}

export default function GroupSearchItemComponent(props: propsInterface) {
  const navigate = useNavigate();

  function toPayGroup() {
    navigate(
      routeGroupPayNavigate(
        String(props.learningWordGroup.learning_word_group_id),
      ),
    );
  }

  function toLearnGroup() {
    navigate(
      routeLearnGroupNavigate(
        String(props.learningWordGroup.learning_word_group_id),
      ),
    );
  }

  function isPaidGroup() {
    return (
      props.paidLearningWordGroupIds.filter((item: number) => {
        return item === props.learningWordGroup.learning_word_group_id;
      }).length > 0
    );
  }

  function ButtonPay() {
    return (
      <>
        <Grid item xs={12} sx={{ alignItems: "center", textAlign: "center" }}>
          <BalanceSymbol />
          <Box component="span" sx={{ fontSize: "30px" }}>
            {props.learningWordGroup.cost}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={toPayGroup}>
            {"Купить"}
          </Button>
        </Grid>
      </>
    );
  }

  function ButtonLearn() {
    return (
      <>
        <Button variant="contained" color="success" onClick={toLearnGroup}>
          {"Изучать"}
        </Button>
      </>
    );
  }

  function toEnvironment() {
    if (
      props.learningWordGroup.environment?.learning_word_group_environment_id
    ) {
      navigate(
        routeGroupsEnvironmentNavigate(
          String(
            props.learningWordGroup.environment
              .learning_word_group_environment_id,
          ),
        ),
      );
    }
  }

  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item sx={{ mr: 3, width: 100, textAlign: "center" }}>
          {isPaidGroup() ? <ButtonLearn /> : <ButtonPay />}
        </Grid>
        <Grid item xs sx={{ p: "5px" }}>
          <Box sx={{ fontWeight: "bold", fontSize: "16px", pb: "5px" }}>
            {props.learningWordGroup.title}
          </Box>
          <Box sx={{ color: "text.secondary", fontSize: "12px", mb: "5px" }}>
            {props.learningWordGroup.preview}
          </Box>
          <Divider variant="fullWidth" />
          <Box>
            <Box
              sx={{ color: "text.secondary", fontSize: "12px", mp: "5px" }}
              component={"span"}
            >
              {props.learningWordGroup.partition.partition}
            </Box>
            {", "}
            {props.learningWordGroup.environment !== null ? (
              <>
                <Box
                  sx={{ color: "text.secondary", fontSize: "12px", mp: "5px" }}
                  component={"span"}
                >
                  <Link
                    sx={{ cursor: "pointer" }}
                    component="button"
                    variant="body2"
                    onClick={toEnvironment}
                  >
                    {props.learningWordGroup.environment.environment_title}
                  </Link>
                </Box>
                {", "}
              </>
            ) : (
              ""
            )}

            <Box
              sx={{ color: "text.secondary", fontSize: "12px", mp: "5px" }}
              component={"span"}
            >
              {"Всего слов: "}
              {props.learningWordGroup.info.count_word}
              {/* {", изучают: "}
              {props.learningWordGroup.info.user_learn} */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
