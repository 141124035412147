import { createSlice } from "@reduxjs/toolkit";
import newLearningWord from "../../models/newLearningWord";
import learningWordSoundType from "../../models/learningWordSoundType";
import learningWordTranscriptionType from "../../models/learningWordTranscriptionType";

export interface learnGroupDataStoreInterface {
  issetNotPublicWord: boolean;
  title: string;
  lastNewLearningWord: newLearningWord | null;
  learnGroupDataLoading: boolean;
  learnGroupDataAccess: boolean;
  updateNewWord: boolean;
  complete: boolean;
  progress: number;
  paidAccess: boolean;
  learningWordSoundTypes: learningWordSoundType[];
  learningWordTranscriptionTypes: learningWordTranscriptionType[];
  countLearningWord: number;
  countWords: number;
  countAccessTests: number;
}

const initReduce: learnGroupDataStoreInterface = {
  issetNotPublicWord: false,
  title: "",
  lastNewLearningWord: null,
  learnGroupDataLoading: true,
  learnGroupDataAccess: true,
  updateNewWord: false,
  complete: false,
  progress: 0,
  paidAccess: true,
  learningWordSoundTypes: [],
  learningWordTranscriptionTypes: [],
  countLearningWord: 0,
  countWords: 0,
  countAccessTests: 0,
};

export const learnGroupDataReduceSlice = createSlice({
  name: "learnGroupDataReduce",
  initialState: initReduce,
  reducers: {
    clearLearnGroupData: (state: learnGroupDataStoreInterface) => {
      state.issetNotPublicWord = initReduce.issetNotPublicWord;
      state.title = initReduce.title;
      state.lastNewLearningWord = initReduce.lastNewLearningWord;
      state.learnGroupDataLoading = initReduce.learnGroupDataLoading;
      state.learnGroupDataAccess = initReduce.learnGroupDataAccess;
      state.updateNewWord = initReduce.updateNewWord;
      state.complete = initReduce.complete;
      state.progress = initReduce.progress;
      state.paidAccess = initReduce.paidAccess;
      state.learningWordSoundTypes = initReduce.learningWordSoundTypes;
      state.learningWordTranscriptionTypes =
        initReduce.learningWordTranscriptionTypes;
      state.countLearningWord = initReduce.countLearningWord;
      state.countWords = initReduce.countWords;
      state.countAccessTests = initReduce.countAccessTests;
    },
    setLastNewLearningWord: (
      state: learnGroupDataStoreInterface,
      actions: { payload: newLearningWord; type: string },
    ) => {
      state.lastNewLearningWord = actions.payload;
    },
    setLearnGroupDataLoadingTrue: (state: learnGroupDataStoreInterface) => {
      state.learnGroupDataLoading = true;
    },
    setLearnGroupDataLoadingFalse: (state: learnGroupDataStoreInterface) => {
      state.learnGroupDataLoading = false;
    },
    setLearnGroupDataAccessTrue: (state: learnGroupDataStoreInterface) => {
      state.learnGroupDataAccess = true;
    },
    setLearnGroupDataAccessFalse: (state: learnGroupDataStoreInterface) => {
      state.learnGroupDataAccess = false;
    },
    setIssetNotPublicWord: (
      state: learnGroupDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.issetNotPublicWord = actions.payload;
    },
    setTitle: (
      state: learnGroupDataStoreInterface,
      actions: { payload: string; type: string },
    ) => {
      state.title = actions.payload;
    },
    setUpdateNewWord: (
      state: learnGroupDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.updateNewWord = actions.payload;
    },
    setComplete: (
      state: learnGroupDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.complete = actions.payload;
    },
    setProgress: (
      state: learnGroupDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.progress = actions.payload;
    },
    setCountLearningWord: (
      state: learnGroupDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.countLearningWord = actions.payload;
    },
    setCountWords: (
      state: learnGroupDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.countWords = actions.payload;
    },
    setPaidAccess: (
      state: learnGroupDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.paidAccess = actions.payload;
    },
    setLearningWordSoundTypes: (
      state: learnGroupDataStoreInterface,
      actions: { payload: learningWordSoundType[]; type: string },
    ) => {
      state.learningWordSoundTypes = actions.payload;
    },
    setLearningWordTranscriptionTypes: (
      state: learnGroupDataStoreInterface,
      actions: { payload: learningWordTranscriptionType[]; type: string },
    ) => {
      state.learningWordTranscriptionTypes = actions.payload;
    },
    setCountAccessTests: (
      state: learnGroupDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.countAccessTests = actions.payload;
    },
  },
});

export const {
  clearLearnGroupData,
  setLastNewLearningWord,
  setLearnGroupDataLoadingTrue,
  setLearnGroupDataLoadingFalse,
  setLearnGroupDataAccessTrue,
  setLearnGroupDataAccessFalse,
  setIssetNotPublicWord,
  setTitle,
  setUpdateNewWord,
  setComplete,
  setProgress,
  setPaidAccess,
  setLearningWordSoundTypes,
  setLearningWordTranscriptionTypes,
  setCountLearningWord,
  setCountWords,
  setCountAccessTests,
} = learnGroupDataReduceSlice.actions;

export default learnGroupDataReduceSlice.reducer;
