import { alertAppError } from "../../constants/alertConstants";
import { API_LEARN_TEST } from "../../constants/apiRoutConstants";
import {
  CODE_FORM_ERRORS,
  CODE_NOT_FOUND_GROUP,
  CODE_NOT_FOUND_LEARN_GROUP_PROCESS,
  NOT_ACCESS_TEST,
  STATUS_FORBIDDEN,
} from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearningWordSoundTypesDispatch,
  setLearningWordTranscriptionTypesDispatch,
} from "../../redux/learnGroupData/learnGroupDataDispatcher";
import {
  setLearnTestAccessFalse,
  setLearnTestAccessTrue,
  setLearnTestLoadingFalse,
  setLearnTestLoadingTrue,
  setTestData,
} from "../../redux/learnTest/learnTestReduce";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { learnTestRequest } from "./learnTestRequest";
import { learnTestResponse } from "./learnTestResponse";

export default function learnTestApi(data: learnTestRequest) {
  startRequest();

  axios
    .get(API_LEARN_TEST, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(setLearnTestLoadingTrue());
};

const successful = (response: responseInterface<learnTestResponse>) => {
  if (
    response.statusCode === STATUS_FORBIDDEN ||
    response.statusCode === CODE_NOT_FOUND_GROUP ||
    response.statusCode === NOT_ACCESS_TEST ||
    response.statusCode === CODE_NOT_FOUND_LEARN_GROUP_PROCESS ||
    response.statusCode === CODE_FORM_ERRORS
  ) {
    store.dispatch(setLearnTestAccessFalse());
    return;
  }

  store.dispatch(setLearnTestAccessTrue());
  store.dispatch(setTestData(response.data.testData));
  setLearningWordSoundTypesDispatch(response.data.learningWordSoundTypes);
  setLearningWordTranscriptionTypesDispatch(
    response.data.learningWordTranscriptionTypes,
  );

  store.dispatch(setLearnTestLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
