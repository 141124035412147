import { alertAppError } from "../../constants/alertConstants";
import { API_LEARN_EXAM } from "../../constants/apiRoutConstants";
import {
  CODE_FORM_ERRORS,
  CODE_NOT_FOUND_GROUP,
  CODE_NOT_FOUND_LEARN_GROUP_PROCESS,
  NOT_ACCESS_EXAM,
  STATUS_FORBIDDEN,
} from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearnExamAccessFalse,
  setLearnExamAccessTrue,
  setLearnExamLoadingFalse,
  setLearnExamLoadingTrue,
  setTestData,
} from "../../redux/learnExam/learnExamReduce";
import {
  setLearningWordSoundTypesDispatch,
  setLearningWordTranscriptionTypesDispatch,
} from "../../redux/learnGroupData/learnGroupDataDispatcher";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { learnExamRequest } from "./learnExamRequest";
import { learnExamResponse } from "./learnExamResponse";

export default function learnExamApi(data: learnExamRequest) {
  startRequest();

  axios
    .get(API_LEARN_EXAM, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(setLearnExamLoadingTrue());
};

const successful = (response: responseInterface<learnExamResponse>) => {
  if (
    response.statusCode === STATUS_FORBIDDEN ||
    response.statusCode === CODE_NOT_FOUND_GROUP ||
    response.statusCode === NOT_ACCESS_EXAM ||
    response.statusCode === CODE_NOT_FOUND_LEARN_GROUP_PROCESS ||
    response.statusCode === CODE_FORM_ERRORS
  ) {
    store.dispatch(setLearnExamAccessFalse());
    return;
  }

  store.dispatch(setLearnExamAccessTrue());
  store.dispatch(setTestData(response.data.testData));
  setLearningWordSoundTypesDispatch(response.data.learningWordSoundTypes);
  setLearningWordTranscriptionTypesDispatch(
    response.data.learningWordTranscriptionTypes,
  );

  store.dispatch(setLearnExamLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
