import { Box } from "@mui/material";
import React from "react";

interface proposalLayoutPropsInterface {
  activeProposalPart: string;
  proposalInArray: string[];
}

export default function ProposalLayout(props: proposalLayoutPropsInterface) {
  function isActive(item: string) {
    return props.activeProposalPart === item;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: "18px",
          cursor: "default",
          p: "15px 0",
        }}
      >
        {props.proposalInArray.map((item: string, index: number) => {
          return (
            <Box
              key={index}
              sx={{ pr: "5px" }}
              color={isActive(item) === true ? "green" : ""}
            >
              {item}
            </Box>
          );
        })}
      </Box>
    </>
  );
}
