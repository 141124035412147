import { storeInterface } from "../store";

const testData = (state: storeInterface) => state.learnTest.testData;
const learnTestLoading = (state: storeInterface) =>
  state.learnTest.learnTestLoading;
const learnTestAccess = (state: storeInterface) =>
  state.learnTest.learnTestAccess;

export default {
  testData,
  learnTestLoading,
  learnTestAccess,
};
