import { alertAppError } from "../../constants/alertConstants";
import { API_CHECK_PAY } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  clearCheckPayDispatch,
  setCancelDispatch,
  setCheckPayLoadingFalseDispatch,
  setErrorDispatch,
  setSuccessDispatch,
} from "../../redux/checkPay/checkPayDispatcher";
import axios, { responseInterface } from "../axios";
import checkPayRequest from "./checkPayRequest";
import checkPayResponse from "./checkPayResponse";

export default function checkPayApi(data: checkPayRequest) {
  startRequest();

  axios
    .post(API_CHECK_PAY, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  clearCheckPayDispatch();
};

const successful = (response: responseInterface<checkPayResponse>) => {
  if (response.statusCode !== STATUS_OK) {
    setErrorDispatch(true);
    setCheckPayLoadingFalseDispatch();
    return;
  }
  setErrorDispatch(false);
  setSuccessDispatch(response.data.success);
  setCancelDispatch(response.data.cancel);
  setCheckPayLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
