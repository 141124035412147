import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_AVAILABLE_LIST } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setAvailableLearningWordGroupsDispatch,
  setGroupAvailableListLoadingFalseDispatch,
  setGroupAvailableListLoadingTrueDispatch,
} from "../../redux/groupAvailableList/groupAvailableListDispatchers";
import axios, { responseInterface } from "../axios";
import groupAvailableListResponse from "./groupAvailableListResponse";

export default function groupAvailableListApi() {
  startRequest();

  axios
    .get(API_GROUP_AVAILABLE_LIST)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setGroupAvailableListLoadingTrueDispatch();
};

const successful = (
  response: responseInterface<groupAvailableListResponse>,
) => {
  setAvailableLearningWordGroupsDispatch(
    response.data.availableLearningWordGroups,
  );
  setGroupAvailableListLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
