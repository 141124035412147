import React from "react";
import { Box, Paper } from "@mui/material";
import selectValueWord from "../../models/selectValueWord";
import TranslatesComponent from "../translates/TranslatesComponent";

interface chooseValueErrorsComponentPropsInterface {
  selectValueWords: selectValueWord[];
}

export default function ChooseValueErrorsComponent(
  props: chooseValueErrorsComponentPropsInterface,
) {
  return (
    <>
      {props.selectValueWords.map(
        (selectValueWord: selectValueWord, key: number) => {
          return (
            <Paper key={key} elevation={3} sx={{ p: "0px", mt: "15px" }}>
              <Box sx={{ fontWeight: "bold", p: "10px 10px 4px 10px" }}>
                {selectValueWord.learningWord.word}
              </Box>
              <Box sx={{ p: "4px 10px" }}>
                <TranslatesComponent
                  translates={selectValueWord.learningWordTranslates}
                  selectType={
                    selectValueWord.success === true ? "good" : "error"
                  }
                  selectValue={selectValueWord.chooseWordTranslate.translate}
                />
              </Box>
            </Paper>
          );
        },
      )}
    </>
  );
}
