import React from "react";
// import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import DiamondIcon from "@mui/icons-material/Diamond";

interface propsInterface {
  fontSize?: number;
}

export default function BalanceSymbol(
  props: propsInterface = {
    fontSize: 14,
  },
) {
  return <DiamondIcon sx={{ fontSize: props.fontSize }} />;
}
