import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ChooseValueErrorsComponent from "../../chooseValueErrors/ChooseValueErrorsComponent";
import selectValueWord from "../../../models/selectValueWord";
import ReturnLearnWordbook from "../../button/ReturnLearnWordbook";

interface propsInterface {
  selectValueWords: selectValueWord[];
  learningWordGroupId: number;
}

export default function NotPassedLayout(props: propsInterface) {
  return (
    <>
      <Box sx={{ padding: "25px 0" }}>
        <Box textAlign={"center"} sx={{ color: "error.main", mb: 3 }}>
          <HighlightOffIcon fontSize="large" />
          <Typography variant="h6">{"Вам почти удалось"}</Typography>
        </Box>
        <Box textAlign={"center"}>
          {"В этот раз не вышло, но всё еще впереди"}
        </Box>
        <Box sx={{ pt: "25px" }}>
          <Divider textAlign="left">{"Ошибки"}</Divider>
          <ChooseValueErrorsComponent
            selectValueWords={props.selectValueWords}
          />
        </Box>
        <Box sx={{ mt: "25px" }} textAlign={"center"}>
          <ReturnLearnWordbook
            learningWordGroupId={props.learningWordGroupId}
          />
        </Box>
      </Box>
    </>
  );
}
