import { storeInterface } from "../store";

const learningWordGroupPartitions = (state: storeInterface) =>
  state.learningWordGroupPartitions.learningWordGroupPartitions;
const learningWordGroupPartitionsLoading = (state: storeInterface) =>
  state.learningWordGroupPartitions.learningWordGroupPartitionsLoading;

export default {
  learningWordGroupPartitions,
  learningWordGroupPartitionsLoading,
};
