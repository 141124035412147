import React from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import { UnSuccessPayComponent } from "../../components/payStatus/UnSuccessPayComponent";

export default function UnSuccessPayPage() {
  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <UnSuccessPayComponent />
      </Container>
      <Footer />
    </>
  );
}
