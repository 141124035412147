import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import WordSearchBarComponent from "../../searchBar/WordSearchBarComponent";
import learningWord from "../../../models/learningWord";
import wordListItem from "../../../models/wordListItem";
import WordsListComponent from "../../wordList/WordListComponent";
import { useSelector } from "react-redux";
import actionWordGroupSelectors from "../../../redux/actionWordGroup/actionWordGroupSelectors";
import store from "../../../redux/store";
import { setWordList } from "../../../redux/actionWordGroup/actionWordGroupReduce";
import {
  setNextStepFalse,
  setNextStepTrue,
} from "../../../redux/stepper/stepperReduce";
import stepperSelectors from "../../../redux/stepper/stepperSelectors";

interface propsInterface {
  disabled: boolean;
}

export default function WordListStepComponent(
  props: propsInterface = {
    disabled: false,
  },
) {
  const wordList = useSelector(actionWordGroupSelectors.wordList);
  const isNextStep = useSelector(stepperSelectors.isNextStep);

  useEffect(() => {
    conditionNextStepHandle(conditionNext());
  }, []);

  useEffect(() => {
    const isNext = conditionNext();

    if (isNextStep !== isNext) {
      conditionNextStepHandle(isNext);
    }
  }, [wordList]);

  function conditionNext() {
    return wordList.length !== 0;
  }

  function conditionNextStepHandle(isNext: boolean) {
    isNext
      ? store.dispatch(setNextStepTrue())
      : store.dispatch(setNextStepFalse());
  }

  function pushItem(learningWord: learningWord) {
    const newItems = copyWordList(wordList);
    const matches = findMatches(learningWord, newItems);

    if (isMatches(matches)) {
      newItems.push({ learningWord: learningWord, remove: false });
      setNewWordList(newItems);
    }
  }

  function copyWordList(wordList: wordListItem[]) {
    return Array.from(wordList);
  }

  function findMatches(learningWord: learningWord, wordList: wordListItem[]) {
    return wordList.filter((wordListItem: wordListItem) => {
      if (
        wordListItem.learningWord.learning_word_id ===
        learningWord.learning_word_id
      ) {
        return true;
      }
    });
  }

  function isMatches(matches: wordListItem[]) {
    return matches.length === 0;
  }

  function setNewWordList(array: wordListItem[]) {
    store.dispatch(setWordList(array));
  }

  return (
    <>
      <Box sx={{ mt: "15px;" }}>
        <Typography variant="caption">
          {"Порядок слов определяет очередность изучения"}
        </Typography>
        <WordSearchBarComponent
          clickItem={pushItem}
          disabled={props.disabled}
        />
        <WordsListComponent
          items={wordList}
          setItems={setNewWordList}
          disabled={props.disabled}
        />
      </Box>
    </>
  );
}
