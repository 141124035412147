import { storeInterface } from "../store";

const checkPayLoading = (state: storeInterface) =>
  state.checkPay.checkPayLoading;
const success = (state: storeInterface) => state.checkPay.success;
const cancel = (state: storeInterface) => state.checkPay.cancel;
const error = (state: storeInterface) => state.checkPay.error;

export default {
  checkPayLoading,
  success,
  cancel,
  error,
};
