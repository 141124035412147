import React, { useState } from "react";
import GroupsSearchLine from "../../components/search/GroupsSearchLine";
import { Box, List, ListItem } from "@mui/material";
import GroupSearchItemComponent from "../../components/item/groups/GroupSearchItemComponent";
import { useNavigate } from "react-router-dom";
import { countPage, elementsForPage } from "../../helpers/paginateHelper";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useSelector } from "react-redux";
import { routeLearnGroupNavigate } from "../../constants/routesConstants";
import groupSearchSelectors from "../../redux/groupSearch/groupSearchSelectors";
import Loader from "../../components/loader/Loader";
import learningWordGroupWithPartitionAndEnvironment from "../../models/learningWordGroupWithPartitionAndEnvironment";

export default function SearchGroupsContainer() {
  const [page, setPage] = useState(1);
  const pageSize = 30;

  const learningWordGroups = useSelector(
    groupSearchSelectors.learningWordGroups,
  );
  const paidLearningWordGroupIds = useSelector(
    groupSearchSelectors.paidLearningWordGroupIds,
  );
  const groupSearchLoading = useSelector(
    groupSearchSelectors.groupSearchLoading,
  );

  function PaginationLayout() {
    const allResponseCount = learningWordGroups.length;
    const allPagesCount = countPage(allResponseCount, pageSize);

    function handlePagination(newPage: number) {
      setPage(newPage);
    }

    return (
      <>
        {allPagesCount <= 1 ? (
          ""
        ) : (
          <PaginationComponent
            count={allPagesCount}
            page={page}
            handleChange={handlePagination}
          />
        )}
      </>
    );
  }

  function EmptyLayout() {
    return (
      <Box sx={{ width: "100%", textAlign: "center", margin: "10px 0" }}>
        {"Ничего не найдено"}
      </Box>
    );
  }

  function ResponseLayout() {
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "center", m: "15px 0" }}>
          <PaginationLayout />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", m: "15px 0" }}>
          <List component="nav" sx={{ width: "90%" }}>
            {elementsForPage({
              array: learningWordGroups,
              pageSize: pageSize,
              page: page,
            }).map(
              (
                learningWordGroup: learningWordGroupWithPartitionAndEnvironment,
                index: number,
              ) => (
                <ListItem
                  key={index}
                  sx={{ backgroundColor: "#eeeeee", m: "5px 0" }}
                >
                  <GroupSearchItemComponent
                    learningWordGroup={learningWordGroup}
                    paidLearningWordGroupIds={paidLearningWordGroupIds}
                  />
                </ListItem>
              ),
            )}
            {learningWordGroups.length === 0 ? <EmptyLayout /> : ""}
          </List>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", m: "15px 0" }}>
          <PaginationLayout />
        </Box>
      </>
    );
  }

  function LoaderLayout() {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <GroupsSearchLine />
      </Box>
      {groupSearchLoading ? <LoaderLayout /> : <ResponseLayout />}
    </>
  );
}
