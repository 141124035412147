import { storeInterface } from "../store";

const learningWordGroupEnvironment = (state: storeInterface) =>
  state.groupEnvironment.learningWordGroupEnvironment;
const learningWordGroups = (state: storeInterface) =>
  state.groupEnvironment.learningWordGroups;
const groupEnvironmentLoading = (state: storeInterface) =>
  state.groupEnvironment.groupEnvironmentLoading;
const groupEnvironmentAccess = (state: storeInterface) =>
  state.groupEnvironment.groupEnvironmentAccess;

export default {
  learningWordGroupEnvironment,
  learningWordGroups,
  groupEnvironmentLoading,
  groupEnvironmentAccess,
};
