import { storeInterface } from "../store";

const learningWordGroupEnvironments = (state: storeInterface) =>
  state.learningWordGroupEnvironments.learningWordGroupEnvironments;
const learningWordGroupEnvironmentsLoading = (state: storeInterface) =>
  state.learningWordGroupEnvironments.learningWordGroupEnvironmentsLoading;

export default {
  learningWordGroupEnvironments,
  learningWordGroupEnvironmentsLoading,
};
