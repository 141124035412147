import { createSlice } from "@reduxjs/toolkit";
import chooseValueWord from "../../models/chooseValueWord";

export interface learnGroupNextStoreInterface {
  learnGroupNext: chooseValueWord | null;
  learnGroupNextLoading: boolean;
  learnGroupNextAccess: boolean;
}

const initStore: learnGroupNextStoreInterface = {
  learnGroupNext: null,
  learnGroupNextLoading: true,
  learnGroupNextAccess: true,
};

export const learnGroupNextReduceSlice = createSlice({
  name: "learnGroupNextReduce",
  initialState: initStore,
  reducers: {
    clearLearnGroupNext: (state: learnGroupNextStoreInterface) => {
      state.learnGroupNext = initStore.learnGroupNext;
      state.learnGroupNextLoading = initStore.learnGroupNextLoading;
      state.learnGroupNextAccess = initStore.learnGroupNextAccess;
    },
    setLearnGroupNextAccessTrue: (state: learnGroupNextStoreInterface) => {
      state.learnGroupNextAccess = true;
    },
    setLearnGroupNextAccessFalse: (state: learnGroupNextStoreInterface) => {
      state.learnGroupNextAccess = false;
    },
    setLearnGroupNext: (
      state: learnGroupNextStoreInterface,
      actions: { payload: chooseValueWord; type: string },
    ) => {
      state.learnGroupNext = actions.payload;
    },
    setLearnGroupNextLoadingTrue: (state: learnGroupNextStoreInterface) => {
      state.learnGroupNextLoading = true;
    },
    setLearnGroupNextLoadingFalse: (state: learnGroupNextStoreInterface) => {
      state.learnGroupNextLoading = false;
    },
  },
});

export const {
  clearLearnGroupNext,
  setLearnGroupNextAccessTrue,
  setLearnGroupNextAccessFalse,
  setLearnGroupNext,
  setLearnGroupNextLoadingTrue,
  setLearnGroupNextLoadingFalse,
} = learnGroupNextReduceSlice.actions;

export default learnGroupNextReduceSlice.reducer;
