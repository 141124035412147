import React, { useEffect } from "react";
import TitleStepComponent from "../../components/stepper/addGroup/TitleStepComponent";
import { Box, Button } from "@mui/material";
import PartitionStepComponent from "../../components/stepper/addGroup/PartitionStepComponent";
import EnvironmentStepComponent from "../../components/stepper/addGroup/EnvironmentStepComponent";
import WordListStepComponent from "../../components/stepper/addGroup/WordListStepComponent";
import Loader from "../../components/loader/Loader";
import { useSelector } from "react-redux";
import actionWordGroupSelectors from "../../redux/actionWordGroup/actionWordGroupSelectors";
import { useNavigate, useParams } from "react-router-dom";
import { FormErrorsText } from "../../components/forms/FormErrorsText";
import NoAccessComponent from "../../components/noAccess/NoAccessComponent";
import groupEditDataApi from "../../api/groupEditData/groupEditDataApi";
import groupEditDataData from "../../api/groupEditData/groupEditDataData";
import store from "../../redux/store";
import { clearActionWordGroup } from "../../redux/actionWordGroup/actionWordGroupReduce";
import groupUpdateService from "../../services/groupUpdate/groupUpdateService";
import { alertSuccess } from "../../redux/alert/alertReduce";
import { alertSuccessUpdate } from "../../constants/alertConstants";
import ErrorTextComponent from "../../components/errorText/ErrorTextComponent";
import { routeGroupTestsNavigate } from "../../constants/routesConstants";

function GroupEditContainer() {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const learningWordGroupId = Number(groupId);
  const actionWordGroupLoading = useSelector(
    actionWordGroupSelectors.actionWordGroupLoading,
  );
  const actionWordGroupError = useSelector(
    actionWordGroupSelectors.actionWordGroupError,
  );
  const actionWordGroupSuccess = useSelector(
    actionWordGroupSelectors.actionWordGroupSuccess,
  );
  const actionWordGroupAccess = useSelector(
    actionWordGroupSelectors.actionWordGroupAccess,
  );
  const isEdit = useSelector(actionWordGroupSelectors.isEdit);
  const isEditWordList = useSelector(actionWordGroupSelectors.isEditWordList);

  useEffect(() => {
    const data = groupEditDataData(learningWordGroupId);
    groupEditDataApi(data);

    return () => {
      store.dispatch(clearActionWordGroup());
    };
  }, []);

  useEffect(() => {
    if (actionWordGroupSuccess === true) {
      store.dispatch(alertSuccess(alertSuccessUpdate));
    }
  }, [actionWordGroupSuccess]);

  function handle() {
    groupUpdateService(learningWordGroupId);
  }

  function isDisable() {
    return isEdit === false;
  }

  function MainLayout() {
    return (
      <>
        <ErrorTextComponent
          errorText={
            isDisable() ? "Нельзя редактировать публичные словари" : ""
          }
        />
        <FormErrorsText propertyValues={actionWordGroupError} />
        <TitleStepComponent disabled={isDisable()} />
        <PartitionStepComponent disabled={isDisable()} />
        <EnvironmentStepComponent disabled={isDisable()} />
        <ErrorTextComponent
          errorText={
            isEditWordList === false
              ? "Нельзя редактировать состав словаря, если у словаря есть созданные тесты и экзамен."
              : ""
          }
        />
        {isEditWordList === false ? (
          <Button
            variant={"contained"}
            onClick={() => {
              navigate(routeGroupTestsNavigate(String(learningWordGroupId)));
            }}
          >
            {"К тестам"}
          </Button>
        ) : (
          ""
        )}
        <WordListStepComponent
          disabled={isEditWordList === false || isDisable()}
        />
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Button variant="contained" onClick={handle} disabled={isDisable()}>
            {"Сохранить"}
          </Button>
        </Box>
      </>
    );
  }

  function LoadingLayout() {
    return <Loader />;
  }

  function AccessView() {
    return <>{actionWordGroupLoading ? <LoadingLayout /> : <MainLayout />}</>;
  }

  return <>{actionWordGroupAccess ? <AccessView /> : <NoAccessComponent />}</>;
}

export default GroupEditContainer;
