import React from "react";
import { Box, Grid } from "@mui/material";
import TestingContainer from "../../../containers/testing/TestingContainer";
import BalanceSymbol from "../../balance/BalanceSymbol";
import PassedLayoutTest from "./PassedLayoutTest";
import NotPassedLayout from "./NotPassedLayout";
import selectValueWord from "../../../models/selectValueWord";
import learnTestProcessData from "../../../api/learnTestProcess/learnTestProcessData";
import learnTestProcessApi from "../../../api/learnTestProcess/learnTestProcessApi";
import testData from "../../../models/testData";

interface propsInterface {
  learningWordGroupTestId: number;
  learningWordGroupId: number;
  learnTestLoading: boolean;
  learnTestProcessLoading: boolean;
  testData: testData;
  complete: boolean | null;
  selectValueWordArray: selectValueWord[];
}

export default function TestLayout(props: propsInterface) {
  function endHandle(requestSelectValueWord: selectValueWord[]) {
    const data = learnTestProcessData(
      props.learningWordGroupTestId,
      requestSelectValueWord,
    );
    learnTestProcessApi(data);
  }

  function Preview() {
    return (
      <>
        <Box sx={{ p: "10px 0" }}>
          <Box
            sx={{ p: "10px 0 25px", textAlign: "center", fontWeight: "bold" }}
          >
            {"Тест проходится на время."}
          </Box>
          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid
              item
              xs={3}
              sx={{
                fontSize: 24,
                color: "success.main",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {"+ " + props.testData.amountPassed}
              <BalanceSymbol />
            </Grid>
            <Grid item xs={6} sx={{ fontSize: "12px", textAlign: "center" }}>
              {"Пройдете тест, "}
              <Box sx={{ color: "success.main" }} component={"span"}>
                {"получите награду"}
              </Box>
              {". Провалитесь - "}
              <Box sx={{ color: "error.main" }} component={"span"}>
                {"получите штраф"}
              </Box>
              {"."}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                fontSize: "24px",
                color: "error.main",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {"- " + props.testData.amountNotPassed}
              <BalanceSymbol />
            </Grid>
          </Grid>
          <Box sx={{ p: "25px 0 0", textAlign: "center", fontWeight: "bold" }}>
            {"Для начала тестирования нажмите на кнопку ниже."}
          </Box>
        </Box>
        <Box sx={{ p: "10px 0" }}></Box>
      </>
    );
  }

  return (
    <>
      <TestingContainer
        endHandle={endHandle}
        preview={<Preview />}
        loading={props.learnTestLoading}
        processLoading={props.learnTestProcessLoading}
        complete={props.complete}
        min={props.testData.min}
        sec={props.testData.sec}
        learningWordGroupId={props.learningWordGroupId}
        data={props.testData.data}
        proposalArray={[]}
        passedLayout={
          <PassedLayoutTest
            learningWordGroupId={props.learningWordGroupId}
            amountPassed={props.testData.amountPassed}
          />
        }
        notPassedLayout={
          <NotPassedLayout
            selectValueWords={props.selectValueWordArray}
            learningWordGroupId={props.learningWordGroupId}
          />
        }
        showNotPassedLayout={props.selectValueWordArray.length !== 0}
      />
    </>
  );
}
