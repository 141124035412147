import { Box, Typography } from "@mui/material";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RestartButton from "../../button/RestartButtonComponent";

interface timeoutLayoutPropsInterface {
  action: CallableFunction;
}

export default function TimeoutLayout(props: timeoutLayoutPropsInterface) {
  return (
    <>
      <Box sx={{ padding: "25px 0" }}>
        <Box textAlign={"center"}>
          <AccessTimeIcon fontSize="large" />
          <Typography variant="h6">{"Вы не успели"}</Typography>
        </Box>
        <Box textAlign={"center"}>
          {"В этот раз не вышло, но всё еще впереди"}
        </Box>
        <RestartButton action={props.action} />
      </Box>
    </>
  );
}
