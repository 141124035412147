import React, { useEffect } from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_GROUPS } from "../../constants/titlesConstants";
import SearchGroupsContainer from "../groupsPage/SearchGroupsContainer";
import groupAllApi from "../../api/groupAll/groupAllApi";
import { clearGroupSearch } from "../../redux/groupSearch/groupSearchReduce";
import store from "../../redux/store";

export default function GroupsPage() {
  useEffect(() => {
    groupAllApi();

    return () => {
      store.dispatch(clearGroupSearch());
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUPS} />
        <SearchGroupsContainer />
      </Container>
      <Footer />
    </>
  );
}
