import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  CssBaseline,
  Divider,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_LEARN_GROUP } from "../../constants/titlesConstants";
import AppLoader from "../../components/loader/AppLoader";
import { clearLearnGroupNextDispatch } from "../../redux/learnGroupNext/learnGroupNextDispatcher";
import { clearLearnGroupCheckDispatch } from "../../redux/learnGroupCheck/learnGroupCheckDispatcher";
import NewLearningWordWordLayout from "../../components/layouts/learn/NewLearningWordWordLayout";
import newLearningWord from "../../models/newLearningWord";
import ChooseValueComponent from "../../components/chooseValue/ChooseValueComponent";
import selectValueWord from "../../models/selectValueWord";
import LearnProgressLayout from "../../components/layouts/learn/LearnProgressLayout";
import { useSelector } from "react-redux";
import learnGroupDataSelectors from "../../redux/learnGroupData/learnGroupDataSelectors";
import learnGroupCheckSelectors from "../../redux/learnGroupCheck/learnGroupCheckSelectors";
import learnGroupNextSelectors from "../../redux/learnGroupNext/learnGroupNextSelectors";
import LastNewLearningWordLayout from "../../components/layouts/learn/LastNewLearningWordLayout";
import { Link, useParams } from "react-router-dom";
import { ROUTE_PAY } from "../../constants/routesConstants";
import TranscriptionsComponent from "../../components/transcriptions/TranscriptionsComponent";
import AudioMultipleComponent from "../../components/audioComponent/AudioMultipleComponent";
import EnglishVariantChecker from "../../components/layouts/learn/EnglishVariantChecker";
import { clearLearnGroupDataDispatch } from "../../redux/learnGroupData/learnGroupDataDispatcher";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";
import appSelectors from "../../redux/app/appSelectors";
import {
  setLastLearnIdDispatch,
  setLastLearnTypeDispatch,
} from "../../redux/app/appDispatcher";
import learnGroupDataData from "../../api/learnGroupData/learnGroupDataData";
import { learnGroupDataApi } from "../../api/learnGroupData/learnGroupDataApi";
import learnGroupNextRequestData from "../../api/learnGroupNext/learnGroupNextRequestData";
import { learnGroupNextApi } from "../../api/learnGroupNext/learnGroupNextApi";
import learnGroupCheckData from "../../api/learnGroupCheck/learnGroupCheckData";
import { learnGroupCheckApi } from "../../api/learnGroupCheck/learnGroupCheckApi";
import TestListContainer from "../learn/TestListContainer";
import NoAccessLearn from "../learn/NoAccessLearn";

export default function LearnGroupPage() {
  const learningWordGroupId = Number(useParams().learningWordGroupId);
  const [isNewWord, setIsNewWord] = useState(false);
  const [newLearningWord, setNewLearningWord] =
    useState<newLearningWord | null>(null);
  const [progress, setProgress] = useState(0);
  const [selectValueWord, setSelectValueWord] =
    useState<selectValueWord | null>(null);

  const learnGroupDataLoading = useSelector(
    learnGroupDataSelectors.learnGroupDataLoading,
  );
  const lastNewLearningWord = useSelector(
    learnGroupDataSelectors.lastNewLearningWord,
  );
  const countLearningWord = useSelector(
    learnGroupDataSelectors.countLearningWord,
  );
  const groupTitle = useSelector(learnGroupDataSelectors.title);
  const countWords = useSelector(learnGroupDataSelectors.countWords);
  const updateNewWord = useSelector(learnGroupDataSelectors.updateNewWord);
  const learnGroupDataProgress = useSelector(learnGroupDataSelectors.progress);
  const learningWordSoundTypes = useSelector(
    learnGroupDataSelectors.learningWordSoundTypes,
  );
  const learningWordTranscriptionTypes = useSelector(
    learnGroupDataSelectors.learningWordTranscriptionTypes,
  );
  const issetNotPublicWord = useSelector(
    learnGroupDataSelectors.issetNotPublicWord,
  );
  const learnGroupDataAccess = useSelector(
    learnGroupDataSelectors.learnGroupDataAccess,
  );
  const learnGroupNextAccess = useSelector(
    learnGroupNextSelectors.learnGroupNextAccess,
  );
  const learnGroupCheckAccess = useSelector(
    learnGroupCheckSelectors.learnGroupCheckAccess,
  );

  const chooseValueWord = useSelector(learnGroupNextSelectors.learnGroupNext);
  const learnGroupNextLoading = useSelector(
    learnGroupNextSelectors.learnGroupNextLoading,
  );

  const learnGroupCheckNewLearningWord = useSelector(
    learnGroupCheckSelectors.newLearningWord,
  );
  const learnGroupCheckLoading = useSelector(
    learnGroupCheckSelectors.learnGroupCheckLoading,
  );
  const learnGroupCheckProgress = useSelector(
    learnGroupCheckSelectors.progress,
  );
  const success = useSelector(learnGroupCheckSelectors.success);

  const paidAccess = useSelector(learnGroupDataSelectors.paidAccess);

  const completeLearningWordChooseTrue = useSelector(
    appSelectors.completeLearningWordChooseTrue,
  );

  const countAccessTests = useSelector(
    learnGroupDataSelectors.countAccessTests,
  );

  useEffect(() => {
    setLastLearnTypeDispatch("group");
    setLastLearnIdDispatch(learningWordGroupId);
    loadData();

    return () => {
      clear();
    };
  }, []);

  function clear() {
    clearLearnGroupDataDispatch();
    clearLearnGroupNextDispatch();
    clearLearnGroupCheckDispatch();
  }

  useEffect(() => {
    setNewLearningWord(lastNewLearningWord);
  }, [lastNewLearningWord]);

  useEffect(() => {
    if (learnGroupCheckNewLearningWord !== null) {
      setNewLearningWord(learnGroupCheckNewLearningWord);
      setIsNewWord(true);
    }
  }, [learnGroupCheckNewLearningWord]);

  useEffect(() => {
    setIsNewWord(updateNewWord);
  }, [updateNewWord]);

  useEffect(() => {
    setProgress(learnGroupDataProgress);
  }, [learnGroupDataProgress]);

  useEffect(() => {
    setProgress(learnGroupCheckProgress);
  }, [learnGroupCheckProgress]);

  useEffect(() => {
    if (selectValueWord !== null) {
      setSelectValueWord({
        ...selectValueWord,
        success: success,
      });
    }

    if (success === true) {
      setTimeout(() => {
        loadNextWord();
        setSelectValueWord(null);
      }, 800);
    }
  }, [success]);

  useEffect(() => {
    if (learnGroupDataLoading === false && chooseValueWord === null) {
      loadNextWord();
    }
  }, [learnGroupDataLoading]);

  function loadData() {
    const data = learnGroupDataData(learningWordGroupId);
    learnGroupDataApi(data);
  }

  function loadNextWord() {
    if (!isContainNotPublicWord()) {
      const data = learnGroupNextRequestData(learningWordGroupId);
      learnGroupNextApi(data);
    }
  }

  function newWordHandle() {
    loadData();
    setIsNewWord(false);
  }

  function knowWordHandle() {
    clear();
    loadData();
  }

  function isStart() {
    return learnGroupDataLoading === false;
  }

  function isNewWordShow() {
    return isStart() && isNewWord === true;
  }

  function isLearnLayoutShow() {
    return (
      !isEmptyGroup() &&
      isStart() &&
      isNewWord === false &&
      !isContainNotPublicWord() &&
      !isCompleteLearn()
    );
  }

  function isCompleteLearn() {
    return chooseValueWord === null && learnGroupNextLoading === false;
  }

  function isContainNotPublicWord() {
    return issetNotPublicWord === true;
  }

  function isEmptyGroup() {
    return countWords === 0;
  }

  function selectValueHandle(select: selectValueWord) {
    setSelectValueWord(select);

    const data = learnGroupCheckData(
      learningWordGroupId,
      select.learningWord.learning_word_id,
      select.chooseWordTranslate.learning_word_translate_id,
    );

    learnGroupCheckApi(data);
  }

  function isShowPaidLayout() {
    return paidAccess === false;
  }

  function ButtonChooseSkeleton() {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          margin: 1,
          minWith: 200,
          p: 2,
          fontWeight: "bold",
          fontSize: 18,
          height: 64,
        }}
      />
    );
  }

  function LoadingLayout() {
    return (
      <>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 61,
          }}
        >
          <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
            <Skeleton variant="text" sx={{ width: 250 }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            fontSize: 20,
            fontStyle: "italic",
            mb: 2,
            height: 32,
          }}
        >
          <Skeleton variant="text" sx={{ width: 150 }} />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "0px",
            color: "#9a9a9a",
          }}
        >
          {"(Выберите правильное значение)"}
        </Box>
        <Box
          display={"flex"}
          justifyContent="center"
          sx={{ marginTop: "15px" }}
        >
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            sx={{
              p: 2,
              width: "100%",
              maxWidth: 400,
            }}
          >
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
          </ButtonGroup>
        </Box>
      </>
    );
  }

  function PaidLayout() {
    return (
      <>
        <Alert severity="warning">
          {"Новые слова не будут появляться."}
          <Box component={"span"} sx={{ pl: 2 }}>
            <Link to={ROUTE_PAY}>{"Оплатитe подписку"}</Link>
          </Box>
        </Alert>
      </>
    );
  }

  function TestsLayout() {
    const [visibleTests, setVisibleTests] = useState(false);

    function testListShow() {
      setVisibleTests(true);
    }

    function testListToggle() {
      setVisibleTests(false);
    }

    return (
      <>
        <TestListContainer
          learningWordGroupId={learningWordGroupId}
          show={visibleTests}
          toggle={testListToggle}
        />
        <Badge badgeContent={countAccessTests} color="primary">
          <Button onClick={testListShow}>{"Тесты и экзамен"}</Button>
        </Badge>
      </>
    );
  }

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container
        maxWidth="md"
        sx={{ minHeight: isContainNotPublicWord() ? "auto" : 550 }}
      >
        {learnGroupDataAccess === true &&
        learnGroupCheckAccess === true &&
        learnGroupNextAccess === true ? (
          <>
            <TitleLineComponent title={TITLE_PAGE_LEARN_GROUP} />
            {isShowPaidLayout() ? <PaidLayout /> : ""}
            {isNewWordShow() && newLearningWord ? (
              <NewLearningWordWordLayout
                newLearningWord={newLearningWord}
                continueHandler={newWordHandle}
                learningWordTranscriptionTypes={learningWordTranscriptionTypes}
                learningWordSoundTypes={learningWordSoundTypes}
                knowWordHandle={knowWordHandle}
              />
            ) : (
              ""
            )}
            {isLearnLayoutShow() ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    mt: 2,
                  }}
                >
                  <Box sx={{ fontWeight: "bold" }}>
                    <Tooltip
                      enterTouchDelay={0}
                      title={
                        "Изучено слов: " +
                        countLearningWord +
                        " из " +
                        countWords
                      }
                    >
                      <Box component={"span"}>
                        {countLearningWord +
                          "/" +
                          countWords +
                          " " +
                          groupTitle}
                      </Box>
                    </Tooltip>
                  </Box>
                  <TestsLayout />
                </Box>
                <EnglishVariantChecker />
                <LearnProgressLayout progress={progress} />
                {chooseValueWord && learnGroupNextLoading === false ? (
                  <>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 61,
                      }}
                    >
                      <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
                        {chooseValueWord.sounds ? (
                          <AudioMultipleComponent
                            learningWordSounds={chooseValueWord.sounds}
                            learningWordSoundTypes={learningWordSoundTypes}
                          />
                        ) : (
                          ""
                        )}
                        {chooseValueWord.learningWord.word}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        fontSize: 20,
                        fontStyle: "italic",
                        mb: 2,
                        height: 32,
                      }}
                    >
                      <TranscriptionsComponent
                        transcriptions={chooseValueWord.transcriptions}
                        learningWordTranscriptionTypes={
                          learningWordTranscriptionTypes
                        }
                      />
                    </Box>
                    <ChooseValueComponent
                      chooseValueWord={chooseValueWord}
                      selectValueHandle={selectValueHandle}
                      selectValueWord={selectValueWord}
                      disable={learnGroupCheckLoading || learnGroupNextLoading}
                    />
                  </>
                ) : (
                  ""
                )}
                {learnGroupNextLoading === true ? <LoadingLayout /> : ""}
              </>
            ) : (
              ""
            )}
            {isLearnLayoutShow() && newLearningWord ? (
              <Box sx={{ mt: "40px" }}>
                <Divider />
                <LastNewLearningWordLayout newLearningWord={newLearningWord} />
              </Box>
            ) : (
              ""
            )}
            {!isStart() ? (
              <>
                <AppLoader />
              </>
            ) : (
              ""
            )}
            {isCompleteLearn() ? (
              <>
                <Box
                  sx={{
                    FontSize: 24,
                    textAlign: "center",
                    mt: 4,
                    mb: 4,
                    fontSize: 24,
                    fontWeight: "bold",
                  }}
                >
                  {"Вы выучили все слова"}
                </Box>
                <Box sx={{ FontSize: 24, textAlign: "center", mb: 1 }}>
                  {"Это значит, что значение каждого изученного из словаря слова вы правильно выбрали более " +
                    completeLearningWordChooseTrue +
                    " раз. Вы навсегда запомнили заничения этих слов. Вам больше нет смысла показывать эти слова."}
                </Box>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <NoAccessLearn groupId={learningWordGroupId} />
        )}
      </Container>
      <Footer />
    </>
  );
}
