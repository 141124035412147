import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import TitleInput from "../../forms/inputs/TitleInput";
import { newEnvironmentHandle } from "../../../services/newEnvironment/newEnvironmentService";
import { useSelector } from "react-redux";
import userDataSelectors from "../../../redux/userData/userDataSelectors";
import { LoadingButton } from "@mui/lab";

interface propsInterface {
  disabled: boolean;
}

export default function NewEnvironmentLayout(
  props: propsInterface = { disabled: false },
) {
  const [title, setTitle] = useState("");
  const newGroupEnvironmentLoading = useSelector(
    userDataSelectors.newGroupEnvironmentLoading,
  );

  function valueChangeHandle(value: string) {
    setTitle(value);
  }

  function isContinueCreateNewEnvironment() {
    return title.length > 0;
  }

  function handle() {
    newEnvironmentHandle(title);
  }

  return (
    <>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid xs={8} item>
          <TitleInput
            title={title}
            loading={newGroupEnvironmentLoading || props.disabled}
            valueChangeHandle={valueChangeHandle}
          />
        </Grid>
        <Grid xs={4} item sx={{ pl: "10px" }}>
          <LoadingButton
            loading={newGroupEnvironmentLoading}
            disabled={!isContinueCreateNewEnvironment() || props.disabled}
            variant="contained"
            onClick={handle}
          >
            {"Создать"}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
