import React from "react";
import EducationStepBase from "./EducationStepBase";
import img223 from "../../../assets/img/education/2_23.jpg";

export default function EducationStep24() {
  return (
    <EducationStepBase
      title={"Баланс"}
      text={
        "Ваш баланс состоит из внутренней валюты, которую купить нельзя, но можно заработать. За каждый правильный выбор слова баланс будет увеличиваться, а за каждый неправильный - убавляться. Баланс не меняется при изучении словарей, которые вы сами создали. Более подробно можно почитать в соответствующем разделе. Для этого нажмите на баланс в правом верхнем углу экрана."
      }
      image={img223}
    />
  );
}
