import axios, { responseInterface } from "../axios";
import { API_LEARN_GROUP_NEXT } from "../../constants/apiRoutConstants";
import {
  STATUS_FORBIDDEN,
  CODE_NOT_FOUND_LEARN_GROUP_PROCESS,
} from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import learnGroupNextResponse from "./learnGroupNextResponse";
import {
  setLearnGroupNextAccessFalseDispatch,
  setLearnGroupNextAccessTrueDispatch,
  setLearnGroupNextDispatch,
  setLearnGroupNextLoadingFalseDispatch,
  setLearnGroupNextLoadingTrueDispatch,
} from "../../redux/learnGroupNext/learnGroupNextDispatcher";
import learnGroupNextRequest from "./learnGroupNextRequest";

export const learnGroupNextApi = (data: learnGroupNextRequest) => {
  startRequest();

  axios
    .get(API_LEARN_GROUP_NEXT, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnGroupNextLoadingTrueDispatch();
};

function successful(response: responseInterface<learnGroupNextResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnGroupNextAccessFalseDispatch();
    setLearnGroupNextLoadingFalseDispatch();
    return;
  }

  if (response.statusCode === CODE_NOT_FOUND_LEARN_GROUP_PROCESS) {
    setLearnGroupNextAccessFalseDispatch();
    setLearnGroupNextLoadingFalseDispatch();
    return;
  }

  setLearnGroupNextAccessTrueDispatch();
  setLearnGroupNextDispatch(response.data.wordChooseTranslate);

  setLearnGroupNextLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
