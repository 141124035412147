import {
  ENGLISH_VARIANT_AMERICAN,
  ENGLISH_VARIANT_BRITISH,
} from "../constants/learnConstants";

const tokenName = "token";
const useCookiesName = "useCookies";
const englishContentVariantName = "englishContentVariant";
const nameLastLearnType = "lastLearnType";
const nameLastLearnId = "lastLearnId";

export const storageGetToken: string =
  window.localStorage.getItem(tokenName) ?? "";

export const storageSetToken = (token: string) => {
  window.localStorage.setItem(tokenName, token);
};

export const storageClearToken = () => {
  window.localStorage.removeItem(tokenName);
};

export const storageUseCookies: string =
  window.localStorage.getItem(useCookiesName) ?? "";

export const storageSetUseCookiesTrue = () => {
  window.localStorage.setItem(useCookiesName, "true");
};

export const storageGetEnglishContentVariant: "british" | "american" =
  window.localStorage.getItem(englishContentVariantName) ===
  ENGLISH_VARIANT_BRITISH
    ? ENGLISH_VARIANT_BRITISH
    : ENGLISH_VARIANT_AMERICAN;

export const storageSetEnglishContentVariant = (
  value: "british" | "american",
) => {
  window.localStorage.setItem(englishContentVariantName, value);
};

export const storageSetLastLearnType = (value: string) => {
  window.localStorage.setItem(nameLastLearnType, value);
};

export const storageSetLastLearnId = (value: string) => {
  window.localStorage.setItem(nameLastLearnId, value);
};

export function storageGetLastLearnType(): null | "group" | "personal" {
  const fromStorage = window.localStorage.getItem(nameLastLearnType) ?? "";
  if (fromStorage === "group") {
    return "group";
  }

  if (fromStorage === "personal") {
    return "personal";
  }

  return null;
}

export function storageGetLastLearnId(): null | number {
  const fromStorage = window.localStorage.getItem(nameLastLearnId) ?? "";

  if (fromStorage === "") {
    return null;
  }

  return Number(fromStorage);
}
