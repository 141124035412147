import React, { useEffect } from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import { SuccessPayComponent } from "../../components/payStatus/SuccessPayComponent";
import checkPayApi from "../../api/checkPay/checkPayApi";
import checkPayData from "../../api/checkPay/checkPayData";
import { clearCheckPayDispatch } from "../../redux/checkPay/checkPayDispatcher";
import { useSelector } from "react-redux";
import checkPaySelectors from "../../redux/checkPay/checkPaySelectors";
import Loader from "../../components/loader/Loader";
import { UnSuccessPayComponent } from "../../components/payStatus/UnSuccessPayComponent";

export default function SuccessPayPage() {
  const [searchParams] = useSearchParams();
  const invId = Number(searchParams.get("InvId"));

  const checkPayLoading = useSelector(checkPaySelectors.checkPayLoading);
  const success = useSelector(checkPaySelectors.success);
  const cancel = useSelector(checkPaySelectors.cancel);
  const error = useSelector(checkPaySelectors.error);

  useEffect(() => {
    const data = checkPayData(invId);
    checkPayApi(data);

    return () => {
      clearCheckPayDispatch();
    };
  }, []);

  useEffect(() => {
    if (success === false && cancel === false) {
      setTimeout(() => {
        const data = checkPayData(invId);
        checkPayApi(data);
      }, 5000);
    }
    console.log(success);
  }, [success, cancel]);

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        {checkPayLoading ? <Loader /> : ""}
        {checkPayLoading === false &&
        success === false &&
        cancel === false &&
        error === false ? (
          <Loader />
        ) : (
          ""
        )}
        {checkPayLoading === false && success === true && error === false ? (
          <SuccessPayComponent />
        ) : (
          ""
        )}
        {checkPayLoading === false && cancel === true && error === false ? (
          <UnSuccessPayComponent />
        ) : (
          ""
        )}
        {checkPayLoading === false && error === true ? (
          <UnSuccessPayComponent />
        ) : (
          ""
        )}
      </Container>
      <Footer />
    </>
  );
}
