import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import EducationStep1 from "./EducationSteps/EducationStep1";
import userCompleteEducationApi from "../../api/userCompleteEducation/userCompleteEducationApi";
import { setUserDataCompleteEducationDispatch } from "../../redux/userData/userDataDispatcher";
import { useSelector } from "react-redux";
import userCompleteEducationSelectors from "../../redux/userCompleteEducation/userCompleteEducationSelectors";
import { LoadingButton } from "@mui/lab";
import EducationStep2 from "./EducationSteps/EducationStep2";
import EducationStep3 from "./EducationSteps/EducationStep3";
import EducationStep4 from "./EducationSteps/EducationStep4";
import EducationStep5 from "./EducationSteps/EducationStep5";
import EducationStep6 from "./EducationSteps/EducationStep6";
import EducationStep8 from "./EducationSteps/EducationStep8";
import EducationStep9 from "./EducationSteps/EducationStep9";
import EducationStep10 from "./EducationSteps/EducationStep10";
import EducationStep11 from "./EducationSteps/EducationStep11";
import EducationStep13 from "./EducationSteps/EducationStep13";
import EducationStep14 from "./EducationSteps/EducationStep14";
import EducationStep15 from "./EducationSteps/EducationStep15";
import EducationStep16 from "./EducationSteps/EducationStep16";
import EducationStep17 from "./EducationSteps/EducationStep17";
import EducationStep18 from "./EducationSteps/EducationStep18";
import EducationStep19 from "./EducationSteps/EducationStep19";
import EducationStep20 from "./EducationSteps/EducationStep20";
import EducationStep21 from "./EducationSteps/EducationStep21";
import EducationStep22 from "./EducationSteps/EducationStep22";
import EducationStep7 from "./EducationSteps/EducationStep7";
import EducationStep12 from "./EducationSteps/EducationStep12";
import EducationStep23 from "./EducationSteps/EducationStep23";
import EducationStep24 from "./EducationSteps/EducationStep24";
import EducationStep25 from "./EducationSteps/EducationStep25";

export default function EducationComponent() {
  const [step, setStep] = useState(1);
  const userCompleteEducationLoading = useSelector(
    userCompleteEducationSelectors.userCompleteEducationLoading,
  );
  const userCompleteEducationComplete = useSelector(
    userCompleteEducationSelectors.userCompleteEducationComplete,
  );

  useEffect(() => {
    if (userCompleteEducationComplete === true) {
      setUserDataCompleteEducationDispatch(true);
    }
  }, [userCompleteEducationComplete]);

  function nextStep() {
    setStep(step + 1);
  }

  function previousStep() {
    setStep(step - 1 < 1 ? 1 : step - 1);
  }

  function stopEducation() {
    userCompleteEducationApi();
  }

  return (
    <>
      <Box sx={{ mt: 6, mb: 6 }}>
        <Paper elevation={12} sx={{ p: 4, maxWidth: 600, m: "0 auto" }}>
          {step === 1 ? <EducationStep1 /> : ""}
          {step === 2 ? <EducationStep2 /> : ""}
          {step === 3 ? <EducationStep3 /> : ""}
          {step === 4 ? <EducationStep4 /> : ""}
          {step === 5 ? <EducationStep5 /> : ""}
          {step === 6 ? <EducationStep6 /> : ""}
          {step === 7 ? <EducationStep7 /> : ""}
          {step === 8 ? <EducationStep8 /> : ""}
          {step === 9 ? <EducationStep9 /> : ""}
          {step === 10 ? <EducationStep10 /> : ""}
          {step === 11 ? <EducationStep11 /> : ""}
          {step === 12 ? <EducationStep12 /> : ""}
          {step === 13 ? <EducationStep13 /> : ""}
          {step === 14 ? <EducationStep14 /> : ""}
          {step === 15 ? <EducationStep15 /> : ""}
          {step === 16 ? <EducationStep16 /> : ""}
          {step === 17 ? <EducationStep17 /> : ""}
          {step === 18 ? <EducationStep18 /> : ""}
          {step === 19 ? <EducationStep19 /> : ""}
          {step === 20 ? <EducationStep20 /> : ""}
          {step === 21 ? <EducationStep21 /> : ""}
          {step === 22 ? <EducationStep22 /> : ""}
          {step === 23 ? <EducationStep23 /> : ""}
          {step === 24 ? <EducationStep24 /> : ""}
          {step === 25 ? <EducationStep25 /> : ""}
          {step === 1 ? (
            <Box sx={{ textAlign: "right", mt: 4 }}>
              <LoadingButton
                loading={userCompleteEducationLoading}
                onClick={stopEducation}
              >
                {"Пропустить"}
              </LoadingButton>
              <LoadingButton
                loading={userCompleteEducationLoading}
                onClick={nextStep}
                variant="contained"
              >
                {"Пройти краткое обучение"}
              </LoadingButton>
            </Box>
          ) : (
            ""
          )}

          {step !== 1 && step !== 25 ? (
            <Box sx={{ textAlign: "right", mt: 4 }}>
              <LoadingButton
                loading={userCompleteEducationLoading}
                onClick={previousStep}
              >
                {"Назад"}
              </LoadingButton>
              <LoadingButton
                loading={userCompleteEducationLoading}
                onClick={nextStep}
                variant="contained"
              >
                {"Далее"}
              </LoadingButton>
            </Box>
          ) : (
            ""
          )}
          {step === 25 ? (
            <Box sx={{ textAlign: "right", mt: 4 }}>
              <LoadingButton
                loading={userCompleteEducationLoading}
                onClick={stopEducation}
                variant="contained"
              >
                {"Перейти к изучению слов"}
              </LoadingButton>
            </Box>
          ) : (
            ""
          )}
        </Paper>
      </Box>
    </>
  );
}
