import learnGroupCheckRequest from "./learnGroupCheckRequest";

export default function learnGroupCheckData(
  learningWordGroupId: number,
  learningWordId: number,
  learningWordTranslateId: number,
): learnGroupCheckRequest {
  return {
    learningWordGroupId: learningWordGroupId,
    learningWordId: learningWordId,
    learningWordTranslateId: learningWordTranslateId,
  };
}
