import { storeInterface } from "../store";

const testData = (state: storeInterface) => state.learnExam.testData;
const learnExamLoading = (state: storeInterface) =>
  state.learnExam.learnExamLoading;
const learnExamAccess = (state: storeInterface) =>
  state.learnExam.learnExamAccess;

export default {
  testData,
  learnExamLoading,
  learnExamAccess,
};
