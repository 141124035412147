import React from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_GROUP_EDIT } from "../../constants/titlesConstants";
import AppLoader from "../../components/loader/AppLoader";
import GroupEditContainer from "../groupEdit/GroupEditContainer";
import BackComponent from "../../components/back/BackComponent";
import { ROUTE_GROUPS_PERSONAL } from "../../constants/routesConstants";

function GroupEditPage() {
  function isStart() {
    return true;
  }

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUP_EDIT} />
        <BackComponent routUrl={ROUTE_GROUPS_PERSONAL} />
      </Container>
      <Container maxWidth="sm">
        {isStart() ? (
          <>
            <GroupEditContainer />
          </>
        ) : (
          <AppLoader />
        )}
      </Container>
      <Footer />
    </>
  );
}

export default GroupEditPage;
