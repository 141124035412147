import selectValueWord from "../../models/selectValueWord";
import { learnExamProcessRequest } from "./learnExamProcessRequest";

export default function learnExamProcessData(
  learningWordGroupId: number,
  data: selectValueWord[],
): learnExamProcessRequest {
  return {
    learningWordGroupId: learningWordGroupId,
    data: data,
  };
}
