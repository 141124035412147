import axios, { responseInterface } from "../axios";
import { API_LEARN_GROUP_DATA } from "../../constants/apiRoutConstants";
import {
  STATUS_FORBIDDEN,
  CODE_NOT_FOUND_LEARN_GROUP_PROCESS,
} from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearnGroupDataAccessFalseDispatch,
  setLearnGroupDataLoadingFalseDispatch,
  setLearnGroupDataLoadingTrueDispatch,
  setCompleteDispatch,
  setLastNewLearningWordDispatch,
  setProgressDispatch,
  setUpdateNewWordDispatch,
  setLearnGroupDataAccessTrueDispatch,
  setPaidAccessDispatch,
  setLearningWordSoundTypesDispatch,
  setLearningWordTranscriptionTypesDispatch,
  setTitleDispatch,
  setCountWordsDispatch,
  setCountLearningWordDispatch,
  setIssetNotPublicWordDispatch,
  setCountAccessTestsDispatch,
} from "../../redux/learnGroupData/learnGroupDataDispatcher";
import learnGroupDataResponse from "./learnGroupDataResponse";
import learnGroupDataRequest from "./learnGroupDataRequest";

export const learnGroupDataApi = (data: learnGroupDataRequest) => {
  startRequest();

  axios
    .get(API_LEARN_GROUP_DATA, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnGroupDataLoadingTrueDispatch();
};

function successful(response: responseInterface<learnGroupDataResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnGroupDataAccessFalseDispatch();
    setLearnGroupDataLoadingFalseDispatch();
    return;
  }

  if (response.statusCode === CODE_NOT_FOUND_LEARN_GROUP_PROCESS) {
    setLearnGroupDataAccessFalseDispatch();
    setLearnGroupDataLoadingFalseDispatch();
    return;
  }

  setLearnGroupDataAccessTrueDispatch();
  setIssetNotPublicWordDispatch(response.data.issetNotPublicWord);
  setTitleDispatch(response.data.title);
  setLastNewLearningWordDispatch(response.data.lastNewLearningWord);
  setUpdateNewWordDispatch(response.data.updateNewWord);
  setCompleteDispatch(response.data.complete);
  setProgressDispatch(response.data.progress);
  setPaidAccessDispatch(response.data.paidAccess);
  setCountLearningWordDispatch(response.data.countLearningWord);
  setCountWordsDispatch(response.data.countWords);
  setLearningWordSoundTypesDispatch(response.data.learningWordSoundTypes);
  setLearningWordTranscriptionTypesDispatch(
    response.data.learningWordTranscriptionTypes,
  );
  setCountAccessTestsDispatch(response.data.countAccessTests);

  setLearnGroupDataLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
