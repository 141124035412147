import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_EDIT_DATA } from "../../constants/apiRoutConstants";
import {
  CODE_NOT_FOUND_GROUP,
  STATUS_FORBIDDEN,
} from "../../constants/errorCodes";
import learningWord from "../../models/learningWord";
import wordListItem from "../../models/wordListItem";
import {
  clearActionWordGroup,
  clearActionWordGroupError,
  setActionWordGroupAccessFalse,
  setActionWordGroupAccessTrue,
  setActionWordGroupLoadingFalse,
  setActionWordGroupLoadingTrue,
  setDescription,
  setIsEditFalse,
  setIsEditTrue,
  setIsEditWordListFalse,
  setIsEditWordListTrue,
  setLearningWordGroupEnvironment,
  setLearningWordGroupPartition,
  setTitle,
  setWordList,
} from "../../redux/actionWordGroup/actionWordGroupReduce";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import { groupEditDataRequest } from "./groupEditDataRequest";
import { groupEditDataResponse } from "./groupEditDataResponse";

export default function groupEditDataApi(data: groupEditDataRequest) {
  startRequest();

  axios
    .get(API_GROUP_EDIT_DATA, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(clearActionWordGroupError());
  store.dispatch(setActionWordGroupLoadingTrue());
};

const successful = (response: responseInterface<groupEditDataResponse>) => {
  if (response.statusCode === STATUS_FORBIDDEN) {
    store.dispatch(setActionWordGroupAccessFalse());
    return;
  }

  if (response.statusCode === CODE_NOT_FOUND_GROUP) {
    store.dispatch(setActionWordGroupAccessFalse());
    return;
  }

  store.dispatch(setActionWordGroupAccessTrue());
  store.dispatch(clearActionWordGroup());
  store.dispatch(setTitle(response.data.learningWordGroup.title));
  store.dispatch(setDescription(response.data.learningWordGroup.preview));
  store.dispatch(
    setLearningWordGroupPartition(response.data.learningWordGroupPartition),
  );

  if (response.data.learningWordGroupEnvironment !== null) {
    store.dispatch(
      setLearningWordGroupEnvironment(
        response.data.learningWordGroupEnvironment,
      ),
    );
  }

  const wordListItems: wordListItem[] = response.data.learningWords.map(
    (item: learningWord) => {
      return {
        learningWord: item,
        remove: false,
      };
    },
  );

  store.dispatch(setWordList(wordListItems));

  response.data.isEdit === true
    ? store.dispatch(setIsEditTrue())
    : store.dispatch(setIsEditFalse());

  response.data.isEditWordList === true
    ? store.dispatch(setIsEditWordListTrue())
    : store.dispatch(setIsEditWordListFalse());

  store.dispatch(setActionWordGroupLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
