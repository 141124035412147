import React from "react";
import EducationStepBase from "./EducationStepBase";
import img220 from "../../../assets/img/education/2_20.jpg";

export default function EducationStep21() {
  return (
    <EducationStepBase
      title={"Словари и доступные словари"}
      text={
        'В разделе "Словари" можно купить готовый словарь за внутреннюю валюту.'
      }
      image={img220}
    />
  );
}
