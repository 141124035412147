import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import RouteList from "./routes/RouteList";
import store, { storeInterface } from "./redux/store";
import { stopAppLoaded } from "./redux/app/appReduce";
import AlertComponent from "./components/alert/AlertComponent";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import appSelectors from "./redux/app/appSelectors";
// import UseCookiesComponent from "./components/cookies/UseCookiesComponent";
import userDataApi from "./api/userData/userDataApi";
import userDataSelectors from "./redux/userData/userDataSelectors";
import MetricComponent from "./components/metric/MetricComponent";
import appDataApi from "./api/appData/appDataApi";
import { isApp, isProd } from "./helpers/appHelper";
import MainAppLoader from "./components/loader/MainAppLoader";
import config from "./config/config";
import AppOldVersionComponent from "./components/appOldVersion/AppOldVersionComponent";
import EducationComponent from "./components/education/EducationComponent";
import SystemWorksComponent from "./components/systemWorks/SystemWorksComponent";

function App() {
  const theme = createTheme();
  const appDataLoading = useSelector(appSelectors.appDataLoading);
  const showAppLoader = useSelector(appSelectors.showAppLoader);
  const userDataLoading = useSelector(userDataSelectors.userDataLoading);
  const completeEducation = useSelector(
    userDataSelectors.userData,
  ).completeEducation;
  const token = useSelector((state: storeInterface) => state.app.token);
  const appVersion = useSelector(appSelectors.appVersion);
  const appInUpdate = useSelector(appSelectors.appInUpdate);

  useEffect(() => {
    appDataApi();

    if (token.length > 0) {
      userDataApi();
    } else {
      store.dispatch(stopAppLoaded());
    }
  }, []);

  useEffect(() => {
    if (userDataLoading === false) {
      store.dispatch(stopAppLoaded());
    }
  }, [userDataLoading]);

  function isAppLoading() {
    return appDataLoading === true || showAppLoader === true;
  }

  function isAppVersionOld() {
    const [major, minor] = separateVersion(appVersion);
    const [appMajor, appMinor] = separateVersion(config.appVersion);

    return isApp() && (major - appMajor > 1 || minor - appMinor > 5);
  }

  function separateVersion(version: number) {
    const parts = String(version).split(".");

    return [Number(parts[0]), Number(parts[1])];
  }

  function isAppInUpdate() {
    return appInUpdate === true;
  }

  function isCompleteEducation() {
    return completeEducation === true;
  }

  return (
    <>
      <AlertComponent />
      <ThemeProvider theme={theme}>
        {/* <UseCookiesComponent /> */}
        <BrowserRouter>
          {isAppLoading() ? <MainAppLoader /> : ""}
          {!isAppLoading() &&
          !isAppVersionOld() &&
          !isCompleteEducation() &&
          !isAppInUpdate() ? (
            <EducationComponent />
          ) : (
            ""
          )}
          {!isAppLoading() && isAppVersionOld() && !isAppInUpdate() ? (
            <AppOldVersionComponent />
          ) : (
            ""
          )}
          {!isAppLoading() &&
          !isAppVersionOld() &&
          isCompleteEducation() &&
          !isAppInUpdate() ? (
            <RouteList />
          ) : (
            ""
          )}
          {!isAppLoading() && isAppInUpdate() ? <SystemWorksComponent /> : ""}
        </BrowserRouter>
        {isProd() ? <MetricComponent /> : ""}
      </ThemeProvider>
    </>
  );
}

export default App;
