import { alertAppError } from "../../constants/alertConstants";
import { API_LEARN_START } from "../../constants/apiRoutConstants";
import {
  CODE_INSUFFICIENT_FUNDS,
  CODE_NOT_FOUND_GROUP,
  CODE_GROUP_LEARN,
} from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearnStartAccessFalse,
  setLearnStartAccessTrue,
  setLearnStartCompleteFalse,
  setLearnStartCompleteTrue,
  setLearnStartEnoughsFundsFalse,
  setLearnStartLoadingFalse,
  setLearnStartLoadingTrue,
} from "../../redux/learnStart/learnStartReduce";
import store from "../../redux/store";
import { updateUserDataBalance } from "../../redux/userData/userDataReduce";
import axios, { responseInterface } from "../axios";
import { learnStartRequest } from "./learnStartRequest";
import { learnStartResponse } from "./learnStartResponse";

export default function learnStartApi(data: learnStartRequest) {
  startRequest();

  axios
    .post(API_LEARN_START, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(setLearnStartLoadingTrue());
};

const successful = (response: responseInterface<learnStartResponse>) => {
  if (response.statusCode === CODE_NOT_FOUND_GROUP) {
    store.dispatch(setLearnStartAccessFalse());
    return;
  }

  if (response.statusCode === CODE_GROUP_LEARN) {
    store.dispatch(setLearnStartAccessTrue());
    store.dispatch(setLearnStartCompleteTrue());
    store.dispatch(setLearnStartLoadingFalse());
    return;
  }

  if (response.statusCode === CODE_INSUFFICIENT_FUNDS) {
    store.dispatch(setLearnStartCompleteFalse());
    store.dispatch(setLearnStartEnoughsFundsFalse());
    store.dispatch(setLearnStartLoadingFalse());
    return;
  }

  store.dispatch(setLearnStartAccessTrue());
  store.dispatch(setLearnStartCompleteTrue());
  store.dispatch(updateUserDataBalance(response.data.balance));
  store.dispatch(setLearnStartLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
