import React, { useEffect } from "react";
import learningWordGroupPartitionsApi from "../../../api/learningWordGroupPartitions/learningWordGroupPartitionsApi";
import store from "../../../redux/store";
import { clearLearningWordGroupPartitions } from "../../../redux/learningWordGroupPartitions/learningWordGroupPartitionsReduce";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useSelector } from "react-redux";
import learningWordGroupPartitionsSelectors from "../../../redux/learningWordGroupPartitions/learningWordGroupPartitionsSelectors";
import Loader from "../../loader/Loader";
import learningWordGroupPartition from "../../../models/learningWordGroupPartition";

interface propsInterface {
  value: number;
  changeHandle: CallableFunction;
}

export default function PartitionsFilter(props: propsInterface) {
  const learningWordGroupPartitions = useSelector(
    learningWordGroupPartitionsSelectors.learningWordGroupPartitions,
  );
  const learningWordGroupPartitionsLoading = useSelector(
    learningWordGroupPartitionsSelectors.learningWordGroupPartitionsLoading,
  );

  useEffect(() => {
    learningWordGroupPartitionsApi();

    return () => {
      store.dispatch(clearLearningWordGroupPartitions());
    };
  }, []);

  function LoaderLayout() {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  function handleChange(event: SelectChangeEvent<number>) {
    const selectLearningWordGroupPartition = learningWordGroupPartitions.filter(
      (item: learningWordGroupPartition) => {
        return item.learning_word_group_partition_id === event.target.value;
      },
    );

    props.changeHandle(event.target.value);
  }

  function MainLayout() {
    return (
      <Select
        name={"partitionId"}
        value={props.value}
        onChange={handleChange}
        sx={{ width: "100%", fontSize: "14px;" }}
        size="small"
      >
        <MenuItem value={0}>{"-- Выберите раздел --"}</MenuItem>
        {learningWordGroupPartitions.map(
          (item: learningWordGroupPartition, index: number) => (
            <MenuItem key={index} value={item.learning_word_group_partition_id}>
              {item.partition}
            </MenuItem>
          ),
        )}
      </Select>
    );
  }

  return (
    <>
      {learningWordGroupPartitionsLoading ? <LoaderLayout /> : <MainLayout />}
    </>
  );
}
