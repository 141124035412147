import { Box, Button } from "@mui/material";
import React from "react";
import learningWordGroupTestListItem from "../../models/learningWordGroupTestListItem";
import { useNavigate } from "react-router-dom";
import { routeLearnTestGroupNavigate } from "../../constants/routesConstants";

interface testListItemComponentPropsInterface {
  learningWordGroupTestListItem: learningWordGroupTestListItem;
  learningWordGroupId: number;
}

export default function TestListItemComponent(
  props: testListItemComponentPropsInterface,
) {
  const navigate = useNavigate();

  function clickHandle() {
    navigate(
      routeLearnTestGroupNavigate(
        String(props.learningWordGroupId),
        String(
          props.learningWordGroupTestListItem.learningWordGroupTest
            .learning_word_group_test_id,
        ),
      ),
    );
  }

  function EndHandleLayout() {
    return (
      <Box sx={{ fontSize: "14px", alignSelf: "center" }}>{"ПРОЙДЕН"}</Box>
    );
  }

  function StartHandleLayout() {
    return (
      <Box sx={{ alignSelf: "center" }}>
        <Button onClick={clickHandle} variant="contained" size="small">
          {"Начать"}
        </Button>
      </Box>
    );
  }

  function NotAccessHandleLayout() {
    return <Box sx={{ fontSize: 12, alignSelf: "center" }}>{"НЕДОСТУПЕН"}</Box>;
  }

  function NeedChooseLayout() {
    return (
      <Box sx={{ fontSize: 12, alignSelf: "center", color: "error.main" }}>
        {"Еще " +
          props.learningWordGroupTestListItem.needChoose +
          " раз выберите правильное значение"}
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          padding: "5px",
          background: "#f1f1f1",
          mt: "5px",
          marginBottom: "5px",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ alignSelf: "center" }}>
            {"Тест " +
              props.learningWordGroupTestListItem.learningWordGroupTest.start}
          </Box>
          {props.learningWordGroupTestListItem.complete === true &&
          props.learningWordGroupTestListItem.access === true ? (
            <EndHandleLayout />
          ) : (
            ""
          )}
          {props.learningWordGroupTestListItem.complete === false &&
          props.learningWordGroupTestListItem.access === true ? (
            <StartHandleLayout />
          ) : (
            ""
          )}
          {props.learningWordGroupTestListItem.access === false ? (
            <NotAccessHandleLayout />
          ) : (
            ""
          )}
        </Box>
        {props.learningWordGroupTestListItem.needChoose !== 0 ? (
          <NeedChooseLayout />
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
