import React, { useEffect } from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_GROUPS_AVAILABLE } from "../../constants/titlesConstants";
import AvailabilityGroupsContainer from "../groupsPage/AvailabilityGroupsContainer";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import groupAvailableListApi from "../../api/groupAvailableList/groupAvailableListApi";
import { clearGroupAvailableList } from "../../redux/groupAvailableList/groupAvailableListReduce";
import groupAvailableListSelectors from "../../redux/groupAvailableList/groupAvailableListSelectors";

export default function GroupsAvailablePage() {
  const groupAvailableListLoading = useSelector(
    groupAvailableListSelectors.groupAvailableListLoading,
  );
  const availableLearningWordGroups = useSelector(
    groupAvailableListSelectors.availableLearningWordGroups,
  );

  useEffect(() => {
    groupAvailableListApi();

    return () => {
      store.dispatch(clearGroupAvailableList());
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUPS_AVAILABLE} />
        {groupAvailableListLoading ? (
          <Loader />
        ) : (
          <Container maxWidth="sm">
            <AvailabilityGroupsContainer
              availableLearningWordGroups={availableLearningWordGroups}
            />
          </Container>
        )}
      </Container>
      <Footer />
    </>
  );
}
