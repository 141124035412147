import groupSearchRequest from "./groupSearchRequest";

export default function groupSearchData(
  search: string = "",
  learningWordGroupPartitionId: number | null = null,
): groupSearchRequest {
  return {
    search: search,
    learningWordGroupPartitionId: learningWordGroupPartitionId,
  };
}
