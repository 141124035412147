import { alertAppError } from "../../constants/alertConstants";
import { API_LEARN_EXAM_PROCESS } from "../../constants/apiRoutConstants";
import {
  CODE_FORM_ERRORS,
  CODE_NOT_FOUND_GROUP,
  NOT_ACCESS_TEST,
  STATUS_FORBIDDEN,
} from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setCompleteFalse,
  setCompleteTrue,
  setLearnExamProcessAccessFalse,
  setLearnExamProcessAccessTrue,
  setLearnExamProcessLoadingFalse,
  setLearnExamProcessLoadingTrue,
  setSelectValueWordArray,
} from "../../redux/learnExamProcess/learnExamProcessReduce";
import store from "../../redux/store";
import { updateUserDataBalance } from "../../redux/userData/userDataReduce";
import axios, { responseInterface } from "../axios";
import { learnExamProcessRequest } from "./learnExamProcessRequest";
import { learnExamProcessResponse } from "./learnExamProcessResponse";

export default function learnExamProcessApi(data: learnExamProcessRequest) {
  startRequest();

  axios
    .post(API_LEARN_EXAM_PROCESS, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(setLearnExamProcessLoadingTrue());
};

const successful = (response: responseInterface<learnExamProcessResponse>) => {
  if (
    response.statusCode === STATUS_FORBIDDEN ||
    response.statusCode === CODE_NOT_FOUND_GROUP ||
    response.statusCode === NOT_ACCESS_TEST ||
    response.statusCode === CODE_FORM_ERRORS
  ) {
    store.dispatch(setLearnExamProcessAccessFalse());
    return;
  }

  store.dispatch(setLearnExamProcessAccessTrue());
  store.dispatch(updateUserDataBalance(response.data.balance));
  store.dispatch(setSelectValueWordArray(response.data.data));

  if (response.data.complete === true) {
    store.dispatch(setCompleteTrue());
  }

  if (response.data.complete === false) {
    store.dispatch(setCompleteFalse());
  }

  store.dispatch(setLearnExamProcessLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
