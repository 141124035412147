import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_NEW_ENVIRONMENT } from "../../constants/apiRoutConstants";
import { setLearningWordGroupEnvironment } from "../../redux/actionWordGroup/actionWordGroupReduce";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { pushLearningWordGroupEnvironments } from "../../redux/learningWordGroupEnvironments/learningWordGroupEnvironmentsReduce";
import store from "../../redux/store";
import {
  setNewGroupEnvironmentLoadingFalse,
  setNewGroupEnvironmentLoadingTrue,
} from "../../redux/userData/userDataReduce";
import axios, { responseInterface } from "../axios";
import groupNewEnvironmentRequest from "./groupNewEnvironmentRequest";
import groupNewEnvironmentResponse from "./groupNewEnvironmentResponse";

export default function groupNewEnvironmentApi(
  data: groupNewEnvironmentRequest,
) {
  startRequest();

  axios
    .post(API_GROUP_NEW_ENVIRONMENT, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(setNewGroupEnvironmentLoadingTrue());
};

const successful = (
  response: responseInterface<groupNewEnvironmentResponse>,
) => {
  const learningWordGroupEnvironment =
    response.data.learningWordGroupEnvironment;

  store.dispatch(
    pushLearningWordGroupEnvironments(learningWordGroupEnvironment),
  );

  store.dispatch(setLearningWordGroupEnvironment(learningWordGroupEnvironment));

  store.dispatch(setNewGroupEnvironmentLoadingFalse());
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
