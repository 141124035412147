import { Box, Button } from "@mui/material";
import React from "react";

interface restartButtonPropsInterface {
  action: CallableFunction;
}

export default function RestartButton(props: restartButtonPropsInterface) {
  return (
    <>
      <Box sx={{ mt: "25px" }} textAlign={"center"}>
        <Button variant="contained" onClick={() => props.action()}>
          {"Пройти еще раз"}
        </Button>
      </Box>
    </>
  );
}
