import axios, { responseInterface } from "../axios";
import { API_LEARN_GROUP_CHECK } from "../../constants/apiRoutConstants";
import {
  STATUS_FORBIDDEN,
  CODE_NOT_FOUND_LEARN_GROUP_PROCESS,
} from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import learnGroupCheckResponse from "./learnGroupCheckResponse";
import learnGroupCheckRequest from "./learnGroupCheckRequest";
import {
  setCompleteDispatch,
  setLearnGroupCheckAccessFalseDispatch,
  setLearnGroupCheckAccessTrueDispatch,
  setLearnGroupCheckLoadingFalseDispatch,
  setLearnGroupCheckLoadingTrueDispatch,
  setNewLearningWordDispatch,
  setProgressDispatch,
  setSuccessDispatch,
  setSuccessNullDispatch,
} from "../../redux/learnGroupCheck/learnGroupCheckDispatcher";
import { updateUserDataBalanceDispatch } from "../../redux/userData/userDataDispatcher";

export const learnGroupCheckApi = (data: learnGroupCheckRequest) => {
  startRequest();

  axios
    .post(API_LEARN_GROUP_CHECK, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnGroupCheckLoadingTrueDispatch();
  setSuccessNullDispatch();
};

function successful(response: responseInterface<learnGroupCheckResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnGroupCheckAccessFalseDispatch();
    setLearnGroupCheckLoadingFalseDispatch();
    return;
  }

  if (response.statusCode === CODE_NOT_FOUND_LEARN_GROUP_PROCESS) {
    setLearnGroupCheckAccessFalseDispatch();
    setLearnGroupCheckLoadingFalseDispatch();
    return;
  }

  setLearnGroupCheckAccessTrueDispatch();
  updateUserDataBalanceDispatch(response.data.balance);
  setNewLearningWordDispatch(response.data.newLearningWord);
  setSuccessDispatch(response.data.success);
  setCompleteDispatch(response.data.complete);
  setProgressDispatch(response.data.progress);

  setLearnGroupCheckLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
