import newLearningWord from "../../models/newLearningWord";
import store from "../store";
import {
  clearLearnGroupCheck,
  setComplete,
  setLearnGroupCheckAccessFalse,
  setLearnGroupCheckAccessTrue,
  setLearnGroupCheckLoadingFalse,
  setLearnGroupCheckLoadingTrue,
  setNewLearningWord,
  setProgress,
  setSuccess,
  setSuccessNull,
} from "./learnGroupCheckReduce";

export function clearLearnGroupCheckDispatch() {
  store.dispatch(clearLearnGroupCheck());
}

export function setNewLearningWordDispatch(
  learnGroupCheck: newLearningWord | null,
) {
  store.dispatch(setNewLearningWord(learnGroupCheck));
}

export function setLearnGroupCheckAccessTrueDispatch() {
  store.dispatch(setLearnGroupCheckAccessTrue());
}

export function setLearnGroupCheckAccessFalseDispatch() {
  store.dispatch(setLearnGroupCheckAccessFalse());
}

export function setLearnGroupCheckLoadingTrueDispatch() {
  store.dispatch(setLearnGroupCheckLoadingTrue());
}

export function setLearnGroupCheckLoadingFalseDispatch() {
  store.dispatch(setLearnGroupCheckLoadingFalse());
}

export function setSuccessNullDispatch() {
  store.dispatch(setSuccessNull());
}

export function setSuccessDispatch(success: boolean) {
  store.dispatch(setSuccess(success));
}

export function setCompleteDispatch(complete: boolean) {
  store.dispatch(setComplete(complete));
}

export function setProgressDispatch(progress: number) {
  store.dispatch(setProgress(progress));
}
