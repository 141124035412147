import { storeInterface } from "../store";

const lastNewLearningWord = (state: storeInterface) =>
  state.learnGroupData.lastNewLearningWord;
const learnGroupDataLoading = (state: storeInterface) =>
  state.learnGroupData.learnGroupDataLoading;
const learnGroupDataAccess = (state: storeInterface) =>
  state.learnGroupData.learnGroupDataAccess;
const updateNewWord = (state: storeInterface) =>
  state.learnGroupData.updateNewWord;
const complete = (state: storeInterface) => state.learnGroupData.complete;
const progress = (state: storeInterface) => state.learnGroupData.progress;
const paidAccess = (state: storeInterface) => state.learnGroupData.paidAccess;
const learningWordSoundTypes = (state: storeInterface) =>
  state.learnGroupData.learningWordSoundTypes;
const learningWordTranscriptionTypes = (state: storeInterface) =>
  state.learnGroupData.learningWordTranscriptionTypes;
const countLearningWord = (state: storeInterface) =>
  state.learnGroupData.countLearningWord;
const countWords = (state: storeInterface) => state.learnGroupData.countWords;
const title = (state: storeInterface) => state.learnGroupData.title;
const issetNotPublicWord = (state: storeInterface) =>
  state.learnGroupData.issetNotPublicWord;
const countAccessTests = (state: storeInterface) =>
  state.learnGroupData.countAccessTests;

export default {
  lastNewLearningWord,
  learnGroupDataLoading,
  learnGroupDataAccess,
  updateNewWord,
  complete,
  progress,
  paidAccess,
  learningWordSoundTypes,
  learningWordTranscriptionTypes,
  countLearningWord,
  countWords,
  title,
  issetNotPublicWord,
  countAccessTests,
};
