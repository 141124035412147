import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import learningWordGroupPartitionsSelectors from "../../../redux/learningWordGroupPartitions/learningWordGroupPartitionsSelectors";
import Loader from "../../loader/Loader";
import learningWordGroupPartitionsApi from "../../../api/learningWordGroupPartitions/learningWordGroupPartitionsApi";
import learningWordGroupPartition from "../../../models/learningWordGroupPartition";
import { setLearningWordGroupPartition } from "../../../redux/actionWordGroup/actionWordGroupReduce";
import actionWordGroupSelectors from "../../../redux/actionWordGroup/actionWordGroupSelectors";
import store from "../../../redux/store";
import {
  setNextStepFalse,
  setNextStepTrue,
} from "../../../redux/stepper/stepperReduce";
import { clearLearningWordGroupPartitions } from "../../../redux/learningWordGroupPartitions/learningWordGroupPartitionsReduce";

interface propsInterface {
  disabled: boolean;
}

export default function PartitionStepComponent(
  props: propsInterface = { disabled: false },
) {
  const learningWordGroupPartitions = useSelector(
    learningWordGroupPartitionsSelectors.learningWordGroupPartitions,
  );
  const learningWordGroupPartitionsLoading = useSelector(
    learningWordGroupPartitionsSelectors.learningWordGroupPartitionsLoading,
  );
  const learningWordGroupPartition = useSelector(
    actionWordGroupSelectors.learningWordGroupPartition,
  );

  useEffect(() => {
    learningWordGroupPartitionsApi();

    return () => {
      store.dispatch(clearLearningWordGroupPartitions());
    };
  }, []);

  useEffect(() => {
    if (
      learningWordGroupPartitionsLoading === false &&
      learningWordGroupPartition === null
    ) {
      store.dispatch(
        setLearningWordGroupPartition(learningWordGroupPartitions[0]),
      );
    }
  }, [learningWordGroupPartitionsLoading]);

  useEffect(() => {
    learningWordGroupPartition !== null
      ? store.dispatch(setNextStepTrue())
      : store.dispatch(setNextStepFalse());
  }, [learningWordGroupPartition]);

  function handleChange(event: SelectChangeEvent<number>) {
    const selectLearningWordGroupPartition = learningWordGroupPartitions.filter(
      (item: learningWordGroupPartition) => {
        return item.learning_word_group_partition_id === event.target.value;
      },
    );
    store.dispatch(
      setLearningWordGroupPartition(selectLearningWordGroupPartition[0]),
    );
  }

  function LoaderLayout() {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  function PrimaryLayout() {
    return (
      <Box sx={{ mt: "10px;" }}>
        <InputLabel id="partition">{"* Выберите раздел"}</InputLabel>
        <Select
          value={learningWordGroupPartition?.learning_word_group_partition_id}
          onChange={handleChange}
          sx={{ width: "100%", fontSize: "14px;" }}
          size="small"
          disabled={props.disabled}
        >
          {learningWordGroupPartitions.map(
            (item: learningWordGroupPartition, index: number) => (
              <MenuItem
                key={index}
                value={item.learning_word_group_partition_id}
              >
                {item.partition}
              </MenuItem>
            ),
          )}
        </Select>
      </Box>
    );
  }

  return (
    <>
      {learningWordGroupPartitionsLoading ? (
        <LoaderLayout />
      ) : (
        <PrimaryLayout />
      )}
    </>
  );
}
