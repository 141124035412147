import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import groupSearchRequest from "./groupSearchRequest";
import {
  setGroupSearchLoadingFalse,
  setGroupSearchLoadingTrue,
} from "../../redux/groupSearch/groupSearchReduce";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { alertAppError } from "../../constants/alertConstants";
import groupSearchResponse from "./groupSearchResponse";
import {
  setLearningWordGroupsDispatch,
  setPaidLearningWordGroupIdsDispatch,
} from "../../redux/groupSearch/groupSearchDispatcher";

const GROUP_SEARCH = "/group/search";

export default function groupSearchApi(data: groupSearchRequest) {
  startRequest();

  axios
    .get(GROUP_SEARCH, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(setGroupSearchLoadingTrue());
};

const successful = (response: responseInterface<groupSearchResponse>) => {
  setLearningWordGroupsDispatch(response.data.learningWordGroups);
  setPaidLearningWordGroupIdsDispatch(response.data.paidLearningWordGroupIds);
  store.dispatch(setGroupSearchLoadingFalse());
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
