import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import {
  setGroupSearchLoadingFalse,
  setGroupSearchLoadingTrue,
} from "../../redux/groupSearch/groupSearchReduce";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { alertAppError } from "../../constants/alertConstants";
import groupAllResponse from "./groupAllResponse";
import {
  setLearningWordGroupsDispatch,
  setPaidLearningWordGroupIdsDispatch,
} from "../../redux/groupSearch/groupSearchDispatcher";

const GROUP_ALL = "/group/all";

export default function groupAllApi() {
  startRequest();

  axios
    .get(GROUP_ALL)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(setGroupSearchLoadingTrue());
};

const successful = (response: responseInterface<groupAllResponse>) => {
  setLearningWordGroupsDispatch(response.data.learningWordGroups);
  setPaidLearningWordGroupIdsDispatch(response.data.paidLearningWordGroupIds);
  store.dispatch(setGroupSearchLoadingFalse());
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
