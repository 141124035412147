import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routeLearnGroupNavigate } from "../../constants/routesConstants";

interface propsInterface {
  learningWordGroupId: number;
}

export default function ReturnLearnWordbook(props: propsInterface) {
  const navigate = useNavigate();

  function handle() {
    navigate(routeLearnGroupNavigate(String(props.learningWordGroupId)));
  }
  return (
    <>
      <Button variant="contained" onClick={handle}>
        {"Вернуться к словарю"}
      </Button>
    </>
  );
}
