import React from "react";
import logo from "../../assets/img/logo.webp";
import { Container, Typography } from "@mui/material";
import AppMenu from "../menu/AppMenu";
import { ROUTE_HOME } from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../../routes/RouteMiddleware";
import BalanceComponent from "../balance/BalanceComponent";
import { useSelector } from "react-redux";
import userDataSelectors from "../../redux/userData/userDataSelectors";

export default function AppHeadLine() {
  const navigate = useNavigate();

  const balance = useSelector(userDataSelectors.userData)?.balance ?? 0;

  const toHome = () => {
    navigate(ROUTE_HOME);
  };

  return (
    <>
      <Container
        sx={{
          paddingTop: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <img
          src={logo}
          style={{ width: "40px", cursor: "pointer" }}
          onClick={toHome}
        />
        <Typography
          onClick={toHome}
          sx={{ cursor: "pointer", fontSize: "24px", pl: 1 }}
        >
          {"Easye"}
        </Typography>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isAuth() ? <BalanceComponent balance={balance} /> : ""}
          <AppMenu />
        </Container>
      </Container>
    </>
  );
}
