import groupNewEnvironmentApi from "../../api/groupNewEnvironment/groupNewEnvironmentApi";
import groupNewEnvironmentData from "../../api/groupNewEnvironment/groupNewEnvironmentData";
import { isTitleValueEmpty } from "../formCheckService/formCheckService";

export const newEnvironmentHandle = (title: string) => {
  if (isTitleValueEmpty(title)) {
    return;
  }

  const data = groupNewEnvironmentData(title);
  groupNewEnvironmentApi(data);
};
