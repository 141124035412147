import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupWithPartitionAndEnvironment from "../../models/learningWordGroupWithPartitionAndEnvironment";

export interface groupSearchStoreInterface {
  groupSearchLoading: boolean;
  learningWordGroups: learningWordGroupWithPartitionAndEnvironment[];
  paidLearningWordGroupIds: number[];
}

const initState: groupSearchStoreInterface = {
  groupSearchLoading: true,
  learningWordGroups: [],
  paidLearningWordGroupIds: [],
};

export const groupSearchReduceSlice = createSlice({
  name: "groupSearchReduce",
  initialState: initState,
  reducers: {
    clearGroupSearch: (state: groupSearchStoreInterface) => {
      state.groupSearchLoading = initState.groupSearchLoading;
      state.learningWordGroups = initState.learningWordGroups;
      state.paidLearningWordGroupIds = initState.paidLearningWordGroupIds;
    },
    setGroupSearchLoadingTrue: (state: groupSearchStoreInterface) => {
      state.groupSearchLoading = true;
    },
    setGroupSearchLoadingFalse: (state: groupSearchStoreInterface) => {
      state.groupSearchLoading = false;
    },
    setLearningWordGroups: (
      state: groupSearchStoreInterface,
      action: {
        payload: learningWordGroupWithPartitionAndEnvironment[];
        type: string;
      },
    ) => {
      state.learningWordGroups = action.payload;
    },
    setPaidLearningWordGroupIds: (
      state: groupSearchStoreInterface,
      action: {
        payload: number[];
        type: string;
      },
    ) => {
      state.paidLearningWordGroupIds = action.payload;
    },
  },
});

export const {
  clearGroupSearch,
  setGroupSearchLoadingTrue,
  setGroupSearchLoadingFalse,
  setLearningWordGroups,
  setPaidLearningWordGroupIds,
} = groupSearchReduceSlice.actions;

export default groupSearchReduceSlice.reducer;
