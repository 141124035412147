import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_UPDATE } from "../../constants/apiRoutConstants";
import { STATUS_OK, STATUS_SOME_ERROR_TEXT } from "../../constants/errorCodes";
import {
  clearActionWordGroupError,
  setActionWordGroupError,
  setActionWordGroupLoadingFalse,
  setActionWordGroupLoadingTrue,
  setActionWordGroupSuccessFalse,
  setActionWordGroupSuccessNull,
  setActionWordGroupSuccessTrue,
} from "../../redux/actionWordGroup/actionWordGroupReduce";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import groupUpdateRequest from "./groupUpdateRequest";

export default function groupUpdateApi(data: groupUpdateRequest) {
  startRequest();

  axios
    .post(API_GROUP_UPDATE, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(setActionWordGroupSuccessNull());
  store.dispatch(clearActionWordGroupError());
  store.dispatch(setActionWordGroupLoadingTrue());
};

const successful = (response: responseInterface<any>) => {
  if (response.statusCode !== STATUS_OK) {
    store.dispatch(setActionWordGroupError([STATUS_SOME_ERROR_TEXT]));
    store.dispatch(setActionWordGroupSuccessFalse());
    store.dispatch(setActionWordGroupLoadingFalse());
    return;
  }

  store.dispatch(setActionWordGroupSuccessTrue());
  store.dispatch(setActionWordGroupLoadingFalse());
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
